import { styled } from "styled-components";
import TopLabel from "./TopLabel";
import label from "../../assets/images/label.jpg";
import { colors } from "../../utils";
import { useNavigate } from "react-router-dom";
import ProductsList from "../Products/ProductsList";
import TopFinisher from "./HomeStore/TopFinisher";

const HomeScreens = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("store");
  };

  return (
    <Container>
      <Wrapper>
        {/* <TopLabel
          imageUrl={label}
          labelText="Welcome to Edifice Mart"
          buttonText="Store"
          onButtonClick={handleClick}
        /> */}
        <TopFinisher />
        <Content>
          <ProductTitle>Our Products</ProductTitle>
          <ProductsList dashboardType="store" itemsPerPage={6} />
        </Content>
      </Wrapper>
    </Container>
  );
};

export default HomeScreens;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-direction: column;
  margin: 0rem auto 2rem auto;
`;

const Content = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto 2rem auto;
  flex-direction: column;
  gap: 1.5rem;
`;

const ProductTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 500;
  color: ${colors.black};
`;
