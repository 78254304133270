import React, { useState } from "react";
import { styled } from "styled-components";
import { useParams } from "react-router-dom";
import { MdLockOutline } from "react-icons/md";
import { toast } from "react-hot-toast";
import { useAppSelector } from "../../../app/hooks";
import { selectDistributor } from "../../../app/features/distributorSlice";
import {
  useGetOrderByIdQuery,
  usePayForOrderMutation,
} from "../../../app/services/orderApi";
import { colors, typography } from "../../../utils";
import Spinner from "../../../utils/Spinner";

interface IPay {
  authorizationUrl?: string | undefined;
}

// Update the type of the mutation function
type PayForOrderMutationResponse = {
  data: { authorizationUrl: string };
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: boolean;
};

const DistributorPayments: React.FC<IPay> = ({ authorizationUrl }) => {
  const { orderId } = useParams();
  const { distributor } = useAppSelector(selectDistributor);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const distributorId = distributor.id;
  const { data, isLoading, isError } = useGetOrderByIdQuery({
    orderId,
    distributorId,
  });

  console.log(orderId);
  const [
    payForOrder,
    {
      data: dataPay,
      isSuccess: isSuccessPay,
      isLoading: isLoadingPay,
      isError: isErrorPay,
      error: errorPay,
    },
  ] = usePayForOrderMutation<PayForOrderMutationResponse>();

  const handlePayment = async () => {
    const response = await payForOrder(orderId);

    //@ts-ignore
    if (response.data?.authorizationUrl) {
      setIsRedirecting(true);
      //@ts-ignore
      window.location.href = response.data.authorizationUrl;
    } else {
      toast.error("No payment link. Try again.");
      return;
    }
  };

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <PaymentWrapper>
            <PaymentHeading>
              <MdLockOutline color={colors.C1B} size="1.125rem" />{" "}
              <h1>Payment</h1>
            </PaymentHeading>

            {authorizationUrl ? (
              <a href={authorizationUrl} rel="noreferrer" target={"_blank"}>
                Pay with Paystack
              </a>
            ) : (
              <Button onClick={handlePayment}>
                {`Pay ${
                  data &&
                  data.totalPrice.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                } Now`}
                {isLoadingPay && <Spinner color={colors.C1F} />}
              </Button>
            )}
          </PaymentWrapper>
          {isError ? (
            <p>Oops!!! Something went wrong... </p>
          ) : isLoading ? (
            <Spinner color={colors.C2C} />
          ) : (
            <OrderWrapper>
              <Payment>
                <h2>ORDER</h2>
                <OrderContent>
                  <h3>Order ID: </h3>
                  <p>{data && data.orderId}</p>
                </OrderContent>

                <OrderContent>
                  <h3>Address: </h3>
                  <p>
                    {data.shippingAddress.address}, {data.shippingAddress.city},{" "}
                    {data.shippingAddress.postalCode},{" "}
                    {data.shippingAddress.country}
                  </p>
                </OrderContent>
                <OrderMoney>
                  <h3>items price: </h3>
                  <p>
                    {data &&
                      data.itemsPrice.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </p>
                </OrderMoney>

                <OrderMoney>
                  <h3>total: </h3>
                  <strong>
                    {data &&
                      data.totalPrice.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </strong>
                </OrderMoney>
              </Payment>
            </OrderWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default DistributorPayments;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  gap: 2rem;
`;

const OrderWrapper = styled.div`
  display: flex;
  width: 40%;
  height: fit-content;
  background-color: ${colors.C2E};
  border-radius: 0.5rem;
`;

const Payment = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin: 1rem;

  h2 {
    text-transform: uppercase;
    font: ${typography.Caption};
    color: ${colors.C1B};
    margin-bottom: 1rem;
  }
`;

const OrderContent = styled.div`
  display: flex;
  width: 100%;
  gap: 0.3rem;
  margin-bottom: 0.4rem;

  h3 {
    font: ${typography.Small};
    color: ${colors.C1B};
    font-weight: 600;
    text-transform: uppercase;
  }

  p {
    font: ${typography.Small};
    color: ${colors.C1B};
    text-transform: capitalize;
  }
`;

const OrderMoney = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  width: 100%;
  gap: 0.3rem;
  margin-bottom: 0.6rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  h3 {
    font: ${typography.Small};
    color: ${colors.C1B};
    font-weight: 600;
    text-transform: uppercase;
  }

  p {
    font: ${typography.Small};
    color: ${colors.C1B};
    text-transform: capitalize;
  }

  strong {
    font: ${typography.Small};
    color: ${colors.C1B};
    text-transform: capitalize;
    font-weight: 600;
  }
`;

const PaymentHeading = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  h1 {
    font: ${typography.S4};
    color: ${colors.C1B};
    font-weight: 400;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: auto auto 1rem auto;
  width: 50%;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.4rem;
  flex-direction: column;
  margin-bottom: 1rem;

  h4 {
    text-transform: uppercase;
    font: ${typography.Small};
    color: ${colors.C1B};
  }
`;

const CardDetails = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const PaymentWrapper = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
`;

const Button = styled.button`
  display: flex;
  width: 100%;
  height: fit-content;
  border: none;
  outline: none;
  border-radius: 0.25rem;
  font-size: ${typography.Body};
  padding: 0.6rem 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${colors.C2B};
  color: ${colors.C1F};

  &:hover {
    transform: background-color 0.2s ${colors.C2C};
  }
`;
