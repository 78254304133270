import React, { ReactNode } from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logoutAdmin, selectAdmin } from "../../app/features/adminSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Navigation from "../../common/Navigation";
import { colors } from "../../utils";
import logo from "../../assets/images/logo.png";

const data = [
  {
    id: 1,
    title: "Dashboard",
    linkTo: "/admin-dashboard/home",
  },
  {
    id: 2,
    title: "Admins",
    linkTo: "/admin-dashboard/admins",
  },
  {
    id: 3,
    title: "Products",
    linkTo: "/admin-dashboard/products",
  },
  {
    id: 4,
    title: "Distributors",
    linkTo: "/admin-dashboard/distributors",
  },
  {
    id: 5,
    title: "Orders",
    linkTo: "/admin-dashboard/orders",
  },
  {
    id: 6,
    title: "Raffles",
    linkTo: "/admin-dashboard/raffles",
  },
];

interface IAdminLayout {
  children: ReactNode;
}

const AdminLayout = ({ children }: IAdminLayout) => {
  const { token: adminToken, admin } = useAppSelector(selectAdmin);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logoutAdmin());
    navigate("/sign-in/admin");
    toast.success("You successfully logged out");
  };

  return (
    <ProtectedRoute token={adminToken} linkTo="/sign-in/admin">
      <Navigation
        logoSrc={logo}
        buttonText="Sign out"
        onClick={handleLogout}
        btnBgColor={colors.yellow}
        firstName={admin.firstName}
        userToken={adminToken ?? undefined}
        navItems={data}
        showSearch={false}
        dashboardType="admin"
      />
      {children}
    </ProtectedRoute>
  );
};

export default AdminLayout;
