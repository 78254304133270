import React from "react";
import { styled } from "styled-components";

interface IButton {
  type?: any;
  text: any;
  onClick?: () => void;
  color: string;
  backgroundColor: string;
  border?: string;
  disabled?: boolean;
}

const Button = ({
  type,
  onClick,
  text,
  color,
  backgroundColor,
  border,
  disabled,
}: IButton) => {
  return (
    <Container
      type={type}
      onClick={onClick}
      style={{ backgroundColor, border }}
      disabled={disabled}
    >
      <Wrapper>
        <Text style={{ color: color }}>{text}</Text>
      </Wrapper>
    </Container>
  );
};

export default Button;

const Container = styled.button`
  display: flex;
  outline: none;
  border-radius: 0.25rem;
  width: 100%;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 0.4rem;
  margin: 0.6rem auto;
`;

const Text = styled.p`
  font-size: 0.9rem;
  margin: 0 0.8rem;
`;
