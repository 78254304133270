import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { styled } from "styled-components";
import useForm, { FormValues } from "../../../hooks/useForm";
import { useFetchStatesAndLgas } from "../../../hooks/useFetchStatesAndLgas";
import { CustomError } from "../../../../types";
import { Button, Form, Input, Label } from "../../../common/FormElements";
import Select from "../../../common/FormElements/Select";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import { useAddDistributorMutation } from "../../../app/services/distributorApi";

const initialState: FormValues = {
  email: "",
  firstName: "",
  lastName: "",
  country: "Nigeria",
  tag: "",
  state: "",
  lga: "",
};

interface IAddDistributorStaff {
  closeModal: () => void;
}

const tagData = [
  {
    optionText: "Owner",
    optionValue: "owner",
  },

  {
    optionText: "Rep",
    optionValue: "rep",
  },
];

const AddDistributorStaff = ({ closeModal }: IAddDistributorStaff) => {
  const { states, lgas, setSelectedState } = useFetchStatesAndLgas("");
  const { formValues, handleInputChange } = useForm(initialState);
  const { email, firstName, lastName, phoneNo, country, state, lga, tag } =
    formValues;
  const [addStaff, { data, isLoading, isSuccess, isError, error }] =
    useAddDistributorMutation();

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = e.target.value;
    handleInputChange({
      target: {
        name: "state",
        value: selectedState,
      },
    } as React.ChangeEvent<HTMLInputElement>);
    setSelectedState(selectedState);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (firstName && lastName && email && phoneNo && country && state && lga) {
      await addStaff({
        firstName,
        lastName,
        email,
        phoneNo,
        country,
        state,
        lga,
        tag,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      toast.success("Distributor onboarded successfully!");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Form onSubmit={handleSubmit}>
      <ItemWrapper>
        <h3>Onboard a Distributor</h3>
      </ItemWrapper>
      <SideBySide>
        <ItemWrapper>
          <Input
            type="text"
            value={firstName}
            name="firstName"
            onChange={handleInputChange}
            placeholder="First name"
          />
        </ItemWrapper>
        <ItemWrapper>
          <Input
            type="text"
            value={lastName}
            name="lastName"
            onChange={handleInputChange}
            placeholder="Last name"
          />
        </ItemWrapper>
      </SideBySide>

      <ItemWrapper>
        <Input
          type="email"
          value={email}
          name="email"
          onChange={handleInputChange}
          placeholder="Email"
        />
      </ItemWrapper>

      <ItemWrapper>
        <Input
          type="text"
          value={phoneNo}
          name="phoneNo"
          onChange={handleInputChange}
          placeholder="Phone no"
        />
      </ItemWrapper>
      <ItemWrapper>
        <Select
          value={tag}
          name="tag"
          defaultOption="Select position"
          onChange={handleInputChange}
          options={tagData}
        />
      </ItemWrapper>

      <SideBySide>
        <ItemWrapper>
          <Select
            value={state}
            onChange={handleStateChange}
            name="state"
            defaultOption="Select state"
            options={states.map((state) => ({
              optionValue: state,
              optionText: state,
            }))}
          />
        </ItemWrapper>

        <ItemWrapper>
          <Select
            value={lga}
            onChange={(e: any) => handleInputChange(e)}
            name="lga"
            defaultOption="Select LGA"
            options={lgas.map((lga) => ({
              optionValue: lga,
              optionText: lga,
            }))}
          />
        </ItemWrapper>
      </SideBySide>

      <Button
        type="submit"
        text={isLoading ? <Spinner color={colors.black} /> : "Onboard"}
        color={colors.black}
        backgroundColor={colors.yellow}
        border="none"
        disabled={isLoading}
      />
    </Form>
  );
};

export default AddDistributorStaff;

const ItemWrapper = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const SideBySide = styled.div`
  display: flex;
  flex-direction: row !important;
  width: inherit;
  gap: 0.5rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    flex-direction: column;
  }
`;
