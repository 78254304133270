import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import ByDistributor from "./ByDistributor";
import TabsWrapper from "../../../common/TabsWrapper";
import AllPayments from "./AllPayments";

const tabItems = ["All Payments", "Distributors Payments"];

const AdminPayments = () => {
  const [tab, setTab] = useState<string>(() => {
    const storedTab = sessionStorage.getItem("activeTab");
    return storedTab || tabItems[0];
  });

  useEffect(() => {
    sessionStorage.setItem("activeTab", tab);
  }, [tab]);

  const renderChildren = () => {
    switch (tab) {
      case tabItems[0]:
        return <AllPayments />;
      case tabItems[1]:
        return <ByDistributor />;
      default:
        return <AllPayments />;
    }
  };

  const renderNavItems = () => {
    return (
      <TabList>
        {tabItems.map((item) => (
          <li
            key={item}
            className={item === tab ? "selected" : ""}
            onClick={() => setTab(item)}
          >
            {item}
          </li>
        ))}
      </TabList>
    );
  };

  return (
    <Container>
      <Wrapper>
        <TabsWrapper renderNavItems={renderNavItems}>
          <TabsContent>{renderChildren()}</TabsContent>
        </TabsWrapper>
      </Wrapper>
    </Container>
  );
};

export default AdminPayments;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 1rem auto;
`;

const TabList = styled.ul`
  list-style: none;
  display: flex;
  margin-bottom: 1rem;
  gap: 2rem;

  li {
    padding: 0.2rem 1rem;
    background-color: ${colors.white};
    border-radius: 0.25rem;
    cursor: pointer;
    color: ${colors.dark};
    border: 1px solid ${colors.C2C};

    &.selected {
      background-color: ${colors.C2C};
      color: ${colors.white};
    }
  }
`;

const TabsContent = styled.div`
  display: flex;
  width: 100%;
  height: 40vh;
  overflow: scroll;
  padding-bottom: 2rem;
`;
