import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import src from "../assets/images/logo-thumbnail.png";
import { colors, screens } from "../utils";

interface IDashboardHeading {
  firstName: string;
  text: string;
}

const DashboardHeading = ({ firstName, text }: IDashboardHeading) => {
  return (
    <Container
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <Wrapper>
        <ImageWrapper
          as={motion.div}
          animate={{ scale: [1, 1.1, 1] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "mirror",
          }}
        >
          <img src={src} alt="dashboard" />
        </ImageWrapper>

        <TextContent
          as={motion.div}
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ type: "spring", duration: 2 }}
        >
          <h3>Welcome back, {firstName}</h3>
          <p>{text}</p>
        </TextContent>
      </Wrapper>
    </Container>
  );
};

export default DashboardHeading;

const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.65rem;
  background-color: ${colors.green};
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 2rem;

  @media (max-width: ${screens.tab}) {
    margin: 1rem;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  margin-right: 1rem;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;

  h3 {
    color: ${colors.grey};
    margin-bottom: 0.65rem;
    font-size: 1.2rem;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
    color: ${colors.white};
    font-weight: 400;
  }
`;
