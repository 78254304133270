import React, { ReactNode } from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Navigation from "../../common/Navigation";
import { colors, screens } from "../../utils";
import logo from "../../assets/images/logo.png";
import {
  logoutDistributor,
  selectDistributor,
} from "../../app/features/distributorSlice";
import { styled } from "styled-components";

const data = [
  {
    id: 1,
    title: "Dashboard",
    linkTo: "/distributor-dashboard/home",
  },
  {
    id: 2,
    title: "Orders",
    linkTo: "/distributor-dashboard/orders",
  },
  {
    id: 3,
    title: "Artisans",
    linkTo: "/distributor-dashboard/artisans",
  },
];

interface IDistributorLayout {
  children: ReactNode;
}

const DistributorLayout = ({ children }: IDistributorLayout) => {
  const { token: distributorToken, distributor } =
    useAppSelector(selectDistributor);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logoutDistributor());
    navigate("/sign-in/distributor");
    toast.success("You successfully logged out");
  };

  return (
    <ProtectedRoute token={distributorToken} linkTo="/sign-in/distributor">
      <Navigation
        logoSrc={logo}
        buttonText="Sign out"
        onClick={handleLogout}
        btnBgColor={colors.yellow}
        firstName={distributor.firstName}
        userToken={distributorToken ?? undefined}
        navItems={data}
        showSearch={true}
        dashboardType="distributor"
      />
      {children}
    </ProtectedRoute>
  );
};

export default DistributorLayout;

const SearchWrapper = styled.div`
  display: none;
  width: 90%;
  margin: auto;

  @media (max-width: ${screens.tab}) {
    display: flex;
  }
`;
