import React from "react";
import { styled } from "styled-components";
import { colors } from "../../utils";
import { Link } from "react-router-dom";
import userAvatar from "../../assets/avatars/user.png";

interface IProfile {
  src?: any;
  firstName?: string;
  token?: string;
}

const Profile = ({ src, firstName, token }: IProfile) => {
  return (
    <Container to="/sign-up/distributor">
      {token ? (
        <AvatarWrapper>
          <img src={src ? src : userAvatar} alt="" />
        </AvatarWrapper>
      ) : (
        <TextWrapper>
          <p>{firstName ? firstName : "Sign Up"}</p>
        </TextWrapper>
      )}
    </Container>
  );
};

export default Profile;

const Container = styled(Link)`
  display: flex;
  width: fit-content;
  gap: 0.2rem;
  align-items: center;
  text-decoration: none;
`;

const AvatarWrapper = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  border: 1px solid ${colors.grey};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  width: fit-content;
  max-width: 6rem;

  p {
    font-size: 1rem;
    color: ${colors.dark};
  }
`;
