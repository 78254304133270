import { createGlobalStyle } from "styled-components";


export const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
    }
    #root{
        margin: 0 auto;
    }
 `;
