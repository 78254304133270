import React, { useState, useEffect, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteProductByIdMutation,
  useGetProductByIdQuery,
  useUpdateProductByIdMutation,
} from "../../../app/services/adminApi";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { toast } from "react-hot-toast";
import Spinner from "../../../utils/Spinner";
import { Form, Input } from "../../../common/FormElements";
import Button from "../../../common/Button";
import { CustomError } from "../../../../types";
import {
  deleteImageFromFirebase,
  uploadImages,
} from "../../../lib/firebase-client";

const EditProduct = () => {
  const { id: productId } = useParams();
  const navigate = useNavigate();
  const {
    data: product,
    isLoading,
    isError,
  } = useGetProductByIdQuery(productId);
  const [
    editProduct,
    {
      isSuccess: editIsSuccess,
      isError: editIsError,
      isLoading: editIsLoading,
      error: editError,
    },
  ] = useUpdateProductByIdMutation();

  const [deleteProduct, { isLoading: isDeleting }] =
    useDeleteProductByIdMutation();

  const [formValue, setFormValue] = useState({
    name: "",
    price: "",
    category: "",
    description: "",
    brand: "",
    countInStock: "",
    //images: null as File | null,
    images: [] as string[], // Store existing image URLs
    newImages: [] as File[],
  });

  const {
    name,
    price,
    category,
    description,
    brand,
    countInStock,
    images,
    newImages,
  } = formValue;

  useEffect(() => {
    if (product) {
      setFormValue({
        name: product.name || "",
        price: product.price || "",
        category: product.category || "",
        description: product.description || "",
        brand: product.brand || "",
        countInStock: product.countInStock || "",
        images: product.images || [],
        newImages: [],
      });
    }
  }, [product]);

  // const handleInputChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  // ) => {
  //   const { name, value } = e.target;

  //   if (e.target instanceof HTMLInputElement && e.target.files) {
  //     setFormValue({ ...formValue, [name]: e.target.files[0] });
  //   } else {
  //     setFormValue({ ...formValue, [name]: value });
  //   }
  // };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (e.target instanceof HTMLInputElement && e.target.files) {
      setFormValue({
        ...formValue,
        newImages: [...newImages, ...Array.from(e.target.files)],
      });
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const handleDeleteImage = async (imageUrl: string) => {
    try {
      // Delete the image from Firebase
      await deleteImageFromFirebase(imageUrl);

      // Remove the URL from the `images` array in the product object
      const updatedImages = images.filter((img) => img !== imageUrl);
      setFormValue({
        ...formValue,
        images: updatedImages,
      });

      // Send the updated image array to the server
      await editProduct({
        id: productId,
        data: {
          images: updatedImages,
        },
      }).unwrap();

      toast.success("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image:", error);
      toast.error("Failed to delete image");
    }
  };

  // const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   if (name && price && category && description && brand && countInStock) {
  //     await editProduct({
  //       id: productId,
  //       data: { name, price, category, description, brand, countInStock },
  //     });
  //   }
  // };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (name && price && category && description && brand && countInStock) {
      try {
        const uploadedImageUrls = await uploadImages(
          newImages,
          "products/images"
        );

        await editProduct({
          id: productId,
          data: {
            name,
            price,
            category,
            description,
            brand,
            countInStock,
            images: [...images, ...uploadedImageUrls], // Combine existing and newly uploaded image URLs
          },
        });
      } catch (error) {
        console.error("Error updating product:", error);
        toast.error("Failed to update product");
      }
    }
  };

  useEffect(() => {
    if (editIsSuccess) {
      toast.success("Product updated successfully");
      navigate("/admin-dashboard/products");
    } else if (editIsError) {
      const customError = editError as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
    }
  }, [editIsSuccess, editIsError, editError, navigate]);

  const handleDeleteProduct = async () => {
    if (product) {
      try {
        // Attempt to delete images from Firebase
        if (product.images && product.images.length > 0) {
          const deletionPromises = product.images.map(async (imageUrl: any) => {
            try {
              await deleteImageFromFirebase(imageUrl);
            } catch (error) {
              console.warn(`Image not found in Firebase: ${imageUrl}`);
            }
          });
          await Promise.all(deletionPromises);
        }

        // Delete product from the database
        await deleteProduct(productId).unwrap();

        toast.success("Product deleted successfully");
        navigate("/admin-dashboard/products");
      } catch (error) {
        console.error("Error deleting product:", error);
        toast.error("Failed to delete product");
      }
    }
  };

  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <h2>Edit Product: {name}</h2>
          <Button
            text={isDeleting ? "Deleting..." : "Delete Product"}
            color={colors.white}
            bgColor={colors.red}
            onClick={handleDeleteProduct}
          />
        </TitleWrapper>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <div>Error loading product data.</div>
        ) : (
          product && (
            <Form onSubmit={handleSubmit}>
              <ItemWrapper>
                <label>Name</label>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleInputChange}
                />
              </ItemWrapper>
              <ItemWrapper>
                <label>Price</label>
                <Input
                  type="number"
                  name="price"
                  value={price}
                  onChange={handleInputChange}
                />
              </ItemWrapper>
              <ItemWrapper>
                <label>Description</label>
                <Input
                  type="text"
                  name="description"
                  value={description}
                  onChange={handleInputChange}
                />
              </ItemWrapper>
              <ItemWrapper>
                <label>Category</label>
                <Input
                  type="text"
                  name="category"
                  value={category}
                  onChange={handleInputChange}
                />
              </ItemWrapper>
              <ItemWrapper>
                <label>Brand</label>
                <Input
                  type="text"
                  name="brand"
                  value={brand}
                  onChange={handleInputChange}
                />
              </ItemWrapper>
              <ItemWrapper>
                <label>Count In Stock</label>
                <Input
                  type="number"
                  name="countInStock"
                  value={countInStock}
                  onChange={handleInputChange}
                />
              </ItemWrapper>
              <ItemWrapper>
                <label>Existing Images</label>
                <ImageGrid>
                  {images.map((image, index) => (
                    <ImagePreview key={index}>
                      <img src={image} alt={`Product ${index}`} />
                      <Button
                        text="Delete"
                        color={colors.white}
                        bgColor={colors.red}
                        onClick={() => handleDeleteImage(image)}
                      />
                    </ImagePreview>
                  ))}
                </ImageGrid>
              </ItemWrapper>
              <ItemWrapper>
                <label>Upload New Images</label>
                <FileInput
                  type="file"
                  accept="image/*"
                  onChange={handleInputChange}
                  multiple
                  required={images.length === 0}
                />
              </ItemWrapper>
              <Button
                type="submit"
                text="Update Product"
                border="none"
                borderRadius="0.25rem"
                color={colors.white}
                bgColor={colors.green}
              />
            </Form>
          )
        )}
      </Wrapper>
    </Container>
  );
};

export default EditProduct;

// Styled components
const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 1.5rem;
  margin: 1rem auto;

  @media (max-width: ${screens.tab}) {
    width: 90%;
  }

  h2 {
    color: ${colors.black};
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  margin-top: 0.8rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const ImagePreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 0.5rem;
  }
`;
const FileInput = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  background-color: ${colors.white};
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${colors.primary};
  }
`;
