import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGenerateInvoiceMutation } from "../../../app/services/orderApi";
import { toast } from "react-hot-toast";
import { styled } from "styled-components";
import { colors } from "../../../utils";

const AdminDistributorGenerateInvoice = () => {
  const { distributorId, orderId } = useParams();
  const navigate = useNavigate();

  // Initialize the mutation hook
  const [generateInvoice, { data, error, isLoading }] =
    useGenerateInvoiceMutation();

  const handleGenerateInvoice = async () => {
    if (distributorId && orderId) {
      try {
        await generateInvoice({ distributorId, orderId }).unwrap();
        navigate(
          `/admin-dashboard/orders/${distributorId}/${orderId}/invoice-details`
        );
      } catch (err) {
        toast.error("Error generating invoice");
      }
    } else {
      toast.error("Invalid distributor or order ID.");
    }
  };

  return (
    <Container>
      <Wrapper>
        <h1>Generate Invoice</h1>
        <button onClick={handleGenerateInvoice} disabled={isLoading}>
          {isLoading ? "Generating..." : "Generate Invoice"}
        </button>
      </Wrapper>
    </Container>
  );
};

export default AdminDistributorGenerateInvoice;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 60%;
  margin: 2rem auto;
  justify-content: center;

  button {
    display: flex;
    outline: none;
    border-radius: 0.25rem;
    width: 100%;
    cursor: pointer;
    background-color: ${colors.C1B};
    color: white;
    font-size: 0.9rem;
  }
`;
