import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";
import Logo from "../Logo";
import { hexToRgba } from "../../utils/hexToRgba";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

interface IAuth {
  bgCol?: string;
  splashBgCol?: string;
  children: React.ReactNode;
  contentBgCol?: string;
  headingCol?: string;
  heading?: string;
  subheading?: string;
  titleCol?: string;
  title?: string;
  accountText?: string;
  accountToText?: string;
  accountTo?: string;
  passwordTo?: string;
  passwordText?: string;
}

const Authentication = ({
  bgCol,
  splashBgCol,
  children,
  contentBgCol,
  headingCol,
  heading,
  subheading,
  titleCol,
  title,
  accountText,
  accountToText,
  accountTo,
  passwordTo,
  passwordText,
}: IAuth) => {
  return (
    <Container bgCol={bgCol}>
      <Wrapper>
        <LogoWrapper to="/">
          <img src={logo} alt="" />
        </LogoWrapper>
        <ContentContainer contentBgCol={contentBgCol}>
          <SplashWrapper splashBgCol={splashBgCol}>
            <HeadingWrapper headingCol={headingCol}>
              <h3>{heading}</h3>
              <p>{subheading}</p>
            </HeadingWrapper>
          </SplashWrapper>

          <Content>
            <Title titleCol={titleCol}>{title}</Title>
            <ContentWrapper>{children}</ContentWrapper>
            <div className="account">
              {accountTo && accountToText && (
                <p>
                  {accountText}{" "}
                  <Link className="account-link" to={accountTo}>
                    {accountToText}
                  </Link>
                </p>
              )}
            </div>
            {passwordTo && passwordText && (
              <div className="account">
                <p>
                  Forgot Password?{" "}
                  <Link className="account-link" to={passwordTo}>
                    {passwordText}
                  </Link>
                </p>
              </div>
            )}
          </Content>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default Authentication;

const Container = styled.div<{ bgCol?: string }>`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.bgCol || colors.white};
  height: fit-content;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 1rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    gap: 0;
  }
`;

const LogoWrapper = styled(Link)`
  display: flex;
  width: fit-content;
  overflow: hidden;

  img {
    height: 3rem;
    object-fit: contain;
  }
`;

const ContentContainer = styled.div<{ contentBgCol?: string }>`
  display: flex;
  width: 90%;
  margin: auto;
  height: fit-content;
  border-radius: 0.5rem;
  gap: 2rem;
  backdrop-filter: blur(10px);
  background-color: ${(props) =>
    hexToRgba(props.contentBgCol || colors.white, 0.15)};
  border: 1px solid ${hexToRgba(colors.white, 0.2)};
  box-shadow: 0 4px 6px ${hexToRgba(colors.black, 0.2)};

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
    width: 100%;
  }
`;

const SplashWrapper = styled.div<{ splashBgCol?: string }>`
  display: flex;
  border-radius: 0.5rem;
  width: 100%;
  background-color: ${(props) => props.splashBgCol || colors.blue};
  padding: 2rem 1.5rem 1.5rem;

  @media (max-width: ${screens.tab}) {
    display: none;
    padding: 1rem;
  }
`;

const HeadingWrapper = styled.div<{ headingCol?: string }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.6rem;

  h3 {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.5;
    color: ${(props) => props.headingCol || colors.white};
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
    color: ${(props) => props.headingCol || colors.white};
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem;

  @media (max-width: ${screens.tab}) {
    margin: 0.5rem auto;
    padding: 0 0.5rem;
  }

  .account {
    margin-bottom: 0;

    p {
      font-size: 0.8rem;

      @media Screen and (max-width: ${screens.tab}) {
        font-size: 1rem;
      }
    }

    .account-link {
      font-size: 0.8rem;

      @media Screen and (max-width: ${screens.tab}) {
        font-size: 1rem;
      }
    }
  }
`;

const Title = styled.h2<{ titleCol?: string }>`
  font-size: 2rem;
  font-weight: 300;
  color: ${(props) => props.titleCol || colors.black};
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
