import React, { useState } from "react";
import { useDistributorGetAllProductsQuery } from "../../../app/services/distributorApi";
import { useGetAllProductsQuery } from "../../../app/services/adminApi";
import Spinner from "../../../utils/Spinner";
import { colors, screens } from "../../../utils";
import Pagination from "../../../common/Pagination";
import { styled } from "styled-components";
import ProductsCard from "./ProductsCard";

interface ProductsListProps {
  dashboardType: "store" | "distributor" | "admin";
  itemsPerPage: number;
}

const ProductsList: React.FC<ProductsListProps> = ({
  dashboardType,
  itemsPerPage,
}) => {
  // Call both hooks unconditionally
  const distributorProducts = useDistributorGetAllProductsQuery({});
  const adminProducts = useGetAllProductsQuery({});

  const [currentPage, setCurrentPage] = useState(1);

  // Determine which data to use based on dashboardType
  const {
    data: products,
    isLoading,
    isError,
  } = dashboardType === "admin" ? adminProducts : distributorProducts;

  if (isLoading) {
    return <Spinner color={colors.grey} />;
  }

  if (isError || !products) {
    return <p>Error loading products...</p>;
  }

  // Pin the "Top Finisher" product
  const pinnedProduct = products.find(
    (product: any) => product.name === "Top Finisher"
  );
  const otherProducts = products.filter(
    (product: any) => product.name !== "Top Finisher"
  );
  const sortedProducts = pinnedProduct
    ? [pinnedProduct, ...otherProducts]
    : products;

  // Calculate the total number of pages
  const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);

  // Calculate the index of the products to display for the current page
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = sortedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <ProductsContainer>
      <ProductsWrapper>
        {currentProducts.map((product: any) => (
          <ProductsCard
            key={product._id}
            product={product}
            dashboardType={dashboardType}
          />
        ))}
      </ProductsWrapper>

      <Pagination
        currentPage={currentPage}
        totalItems={sortedProducts.length}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
      />
    </ProductsContainer>
  );
};

export default ProductsList;

// Styling for the component
const ProductsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
`;

const ProductsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: ${screens.minitab}) {
    grid-template-columns: 1fr;
  }
`;
