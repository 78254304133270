import React, { useState } from "react";
import { styled } from "styled-components";
import AllAdmins from "../AllAdmins";
import { useAppSelector } from "../../../app/hooks";
import { selectAdmin } from "../../../app/features/adminSlice";
import Modal from "../../../common/Modal";
import AddAdmin from "./AddAdmin";
import { Button } from "../../../common/FormElements";
import { colors, screens } from "../../../utils";
import AddTopFinisher from "./AddTopFinisher";

const AdminManager = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenTopFinisher, setIsOpenTopFinisher] = useState<boolean>(false);
  const { admin } = useAppSelector(selectAdmin);

  const handleAddAdmin = () => {
    setIsOpen(true);
  };

  const handleAddAdminTopFinisher = () => {
    setIsOpenTopFinisher(true);
  };

  return (
    <Container>
      <Wrapper>
        <AllAdmins />

        <ButtonWrapper>
          {(admin.tag === "ceo" || admin.tag === "manager") && (
            <AddAdminWrapper>
              <h3>Add a New Admin</h3>
              <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <AddAdmin closeModal={() => setIsOpen(false)} />
              </Modal>
              <Button
                type="button"
                color={colors.black}
                backgroundColor={colors.yellow}
                border="none"
                onClick={handleAddAdmin}
                text="Add an admin"
              />
            </AddAdminWrapper>
          )}

          {admin.tag === "ceo" && (
            <AddAdminWrapper>
              <h3>Add Top Finisher Admin</h3>
              <Modal
                open={isOpenTopFinisher}
                onClose={() => setIsOpenTopFinisher(false)}
              >
                <AddTopFinisher closeModal={() => setIsOpenTopFinisher(false)} />
              </Modal>
              <Button
                type="button"
                color={colors.white}
                backgroundColor={colors.green}
                border="none"
                onClick={handleAddAdminTopFinisher}
                text="Add Top Finisher Admin"
              />
            </AddAdminWrapper>
          )}
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default AdminManager;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  gap: 2rem;
  flex-direction: column;
  margin: 2rem auto;
`;

const AddAdminWrapper = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    padding: 0;
    border-left: none;
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.dark};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;
