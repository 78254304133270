import React from "react";
import { styled } from "styled-components";
import ProductsList from "../../Products/ProductsList";

const DistributorMarketplace = () => {
  return (
    <Container>
      <Wrapper>
        <h1>All Products</h1>
        <ProductsList dashboardType="distributor" itemsPerPage={6} />
      </Wrapper>
    </Container>
  );
};

export default DistributorMarketplace;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  flex-direction: column;
  gap: 1rem;
`;
