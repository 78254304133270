import HomeLayout from "../../layouts/HomeLayout";
import { Outlet } from "react-router-dom";

const HomePages = () => {
  return (
    <HomeLayout>
      <Outlet />
    </HomeLayout>
  );
};

export default HomePages;
