import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { styled } from "styled-components";
import { useAppDispatch } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { CustomError } from "../../../types";
import {
  Button,
  Form,
  Input,
  Label,
  PasswordInput,
} from "../../common/FormElements";
import { colors, screens } from "../../utils";
import Spinner from "../../utils/Spinner";
import useForm, { FormValues } from "../../hooks/useForm";
import { useSignupDistributorMutation } from "../../app/services/distributorApi";
import { setDistributor } from "../../app/features/distributorSlice";
import { useFetchStatesAndLgas } from "../../hooks/useFetchStatesAndLgas";
import PasswordCheck from "../../utils/PasswordCheck";
import Select from "../../common/FormElements/Select";

const initialState: FormValues = {
  email: "",
  firstName: "",
  lastName: "",
  country: "Nigeria",
  state: "",
  lga: "",
  password: "",
  confirmPassword: "",
};

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { states, lgas, setSelectedState } = useFetchStatesAndLgas("");
  const { formValues, handleInputChange } = useForm(initialState);
  const [passwordType, setPasswordType] = useState("password");
  const {
    email,
    firstName,
    lastName,
    phoneNo,
    country,
    state,
    lga,
    password,
    confirmPassword,
  } = formValues;
  const [distributorSignUp, { data, isLoading, isSuccess, isError, error }] =
    useSignupDistributorMutation();

  //Toggle Password visibility
  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = e.target.value;
    handleInputChange({
      target: {
        name: "state",
        value: selectedState,
      },
    } as React.ChangeEvent<HTMLInputElement>);
    setSelectedState(selectedState);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      firstName &&
      lastName &&
      email &&
      password &&
      phoneNo &&
      country &&
      state &&
      lga
    ) {
      await distributorSignUp({
        firstName,
        lastName,
        email,
        phoneNo,
        country,
        state,
        lga,
        password,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setDistributor({
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNo: data.phoneNo,
          tag: data.tag,
          token: data.token,
        })
      );
      navigate("/distributor-dashboard/home");
      toast.success("Hello today. Welcome!");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Form onSubmit={handleSubmit}>
      <SideBySide>
        <ItemWrapper>
          <Input
            type="text"
            value={firstName}
            name="firstName"
            onChange={handleInputChange}
            placeholder="First name"
          />
        </ItemWrapper>
        <ItemWrapper>
          <Input
            type="text"
            value={lastName}
            name="lastName"
            onChange={handleInputChange}
            placeholder="Last name"
          />
        </ItemWrapper>
      </SideBySide>

      <ItemWrapper>
        <Input
          type="email"
          value={email}
          name="email"
          onChange={handleInputChange}
          placeholder="Email"
        />
      </ItemWrapper>

      <ItemWrapper>
        <Input
          type="text"
          value={phoneNo}
          name="phoneNo"
          onChange={handleInputChange}
          placeholder="Phone no"
        />
      </ItemWrapper>

      <SideBySide>
        <ItemWrapper>
          <Select
            value={state}
            onChange={handleStateChange}
            name="state"
            defaultOption="Select state"
            options={states.map((state) => ({
              optionValue: state,
              optionText: state,
            }))}
          />
        </ItemWrapper>

        <ItemWrapper>
          <Select
            value={lga}
            onChange={(e: any) => handleInputChange(e)}
            name="lga"
            defaultOption="Select LGA"
            options={lgas.map((lga) => ({
              optionValue: lga,
              optionText: lga,
            }))}
          />
        </ItemWrapper>
      </SideBySide>

      <ItemWrapper>
        <Label text="Password" fontSize="1rem" color={colors.dark} />
        <PasswordInput
          type={passwordType}
          placeholder="Password"
          name="password"
          value={password}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />

        <Label text="Confirm Password" fontSize="1rem" color={colors.dark} />
        <PasswordInput
          type={passwordType}
          placeholder="Confirm Password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />
        <PasswordCheck password={password} />
      </ItemWrapper>

      <Button
        type="submit"
        text={isLoading ? <Spinner color={colors.black} /> : "Sign up"}
        color={colors.black}
        backgroundColor={colors.yellow}
        border="none"
        disabled={isLoading}
      />
    </Form>
  );
};

export default SignUp;

const ItemWrapper = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const SideBySide = styled.div`
  display: flex;
  flex-direction: row !important;
  width: inherit;
  gap: 0.5rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    flex-direction: column;
  }
`;
