import React from "react";
import { styled } from "styled-components";
import DashboardHeading from "../../common/DashboardHeading";
import { useAppSelector } from "../../app/hooks";
import { selectDistributor } from "../../app/features/distributorSlice";
import { colors, screens } from "../../utils";
import DashboardCard from "./DashboardHome/DashboardCard";
import {
  useDistributorGetAllProductsQuery,
  useGetAllStaffQuery,
  useGetOrderAndPaymentForDistributorQuery,
} from "../../app/services/distributorApi";
import { MdOutlinePayments } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { LuShoppingBag } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/FormElements";
import productImage from "../../assets/images/van.webp";

const SUMMARY_DATA = [
  {
    id: 1,
    title: "Orders",
    count: "orders",
    icon: "order",
    linkTo: "/distributor-dashboard/orders",
    backgroundColor: "#E2FBD7",
  },
  {
    id: 2,
    title: "Payments",
    count: "payments",
    icon: "payments",
    linkTo: "/distributor-dashboard/payments",
    backgroundColor: "#FABFA0",
  },
  {
    id: 3,
    title: "Your Staff",
    count: "staff",
    icon: "staff",
    linkTo: "/distributor-dashboard/staff",
    backgroundColor: "#BEDAD8",
  },
];

const DistributorScreens = () => {
  const navigate = useNavigate();
  const { distributor } = useAppSelector(selectDistributor);
  const id = distributor.id;
  const { data, isLoading, isError, isSuccess } =
    useDistributorGetAllProductsQuery({});
  const { data: distData } = useGetOrderAndPaymentForDistributorQuery(id);
  const { data: staffData } = useGetAllStaffQuery(id);

  const handleMarketplace = () => {
    navigate("/distributor-dashboard/marketplace");
  };

  return (
    <Container>
      <Wrapper>
        <DashboardHeading
          text="This is the Distributor Dashboard. Find products, get discounts. Place orders and more with ease."
          firstName={distributor.firstName}
        />

        <CardWrapper>
          {SUMMARY_DATA.map((item) => {
            return (
              <DashboardCard
                key={item.id}
                linkTo={item.linkTo}
                title={item.title}
                count={
                  item.count === "orders"
                    ? `${distData ? distData.totalOrdersCount : 0}`
                    : item.count === "payments"
                    ? `${distData ? distData.totalPaymentsSum : 0}`
                    : item.count === "staff"
                    ? `${staffData ? staffData.distributors.length : 0}`
                    : null
                }
                backgroundColor={item.backgroundColor}
                icon={
                  item.icon === "order" ? (
                    <LuShoppingBag color={colors.black} size="1rem" />
                  ) : item.icon === "staff" ? (
                    <BsPerson color={colors.black} size="1rem" />
                  ) : item.icon === "payments" ? (
                    <MdOutlinePayments color={colors.black} size="1rem" />
                  ) : null
                }
              />
            );
          })}
        </CardWrapper>
        <ContentWrapper>
          <TextContent>
            <h3>Browse Products</h3>
            <p>
              Find products available and get interesting discounts on the fly
            </p>
            <ButtonWrapper>
              <Button
                text="Button to Marketplace"
                color={colors.black}
                backgroundColor="transparent"
                border={`1px solid ${colors.black}`}
                onClick={handleMarketplace}
              />
            </ButtonWrapper>
          </TextContent>
          <ImageWrapper>
            <img src={productImage} alt="" />
          </ImageWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default DistributorScreens;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 1.5rem;
  margin: 1rem auto;

  h2 {
    color: ${colors.black};
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.5rem;
  gap: 2rem;
  margin: 1rem auto;
  background-color: ${colors.blueBack};
  height: fit-content;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 10rem;
  overflow: hidden;
  border-radius: 0 0.5rem 0.5rem 0;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

const TextContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }

  p {
    font-size: 0.8rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;
