import React from 'react'
import HomeLayout from '../../layouts/HomeLayout'
import SignInDefault from '../../components/SignInDefault'

const SignInPage = () => {
  return (
    <HomeLayout>
        <SignInDefault />
    </HomeLayout>
  )
}

export default SignInPage