import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface DistributorState {
  firstName: string;
  lastName: string;
  email: string;
  tag: string;
  id: string | null;
}

interface InitialState {
  distributor: DistributorState;
  token: string | null;
}

const initialState: InitialState = {
  distributor: {
    firstName: "",
    lastName: "",
    email: "",
    tag: "",
    id: null,
  },
  token: null,
};

export const distributorSlice = createSlice({
  name: "distributor",
  initialState,
  reducers: {
    createDistributor: (state, action) => {
      // Handle creating a new Distributor if needed
    },

    setDistributor: (state, action) => {
      const { firstName, lastName, email, tag, id, token } = action.payload;

      localStorage.setItem(
        "distributor",
        JSON.stringify({
          id,
          firstName,
          lastName,
          tag,
          email,
          token,
        })
      );

      state.distributor.id = id;
      state.distributor.firstName = firstName;
      state.distributor.lastName = lastName;
      state.distributor.tag = tag;
      state.distributor.email = email;
      state.token = token;
    },

    logoutDistributor: (state) => {
      localStorage.clear();
      state.distributor.firstName = "";
      state.distributor.lastName = "";
      state.distributor.email = "";
      state.distributor.tag = "";
      state.distributor.id = null;
      state.token = null;
    },
  },
});

export const selectDistributor = (state: RootState) => state.distributor;

export const { createDistributor, setDistributor, logoutDistributor } =
  distributorSlice.actions;

export default distributorSlice.reducer;
