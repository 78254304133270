import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { adminApi } from "./services/adminApi";
import adminReducer from "./features/adminSlice";
import { distributorApi } from "./services/distributorApi";
import distributorReducer from "./features/distributorSlice";
import { orderApi } from "./services/orderApi";
import cartReducer from "./features/cartSlice";
import shippingReducer from "./features/shippingSlice";

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    distributor: distributorReducer,
    cart: cartReducer,
    shipping: shippingReducer,

    [adminApi.reducerPath]: adminApi.reducer,
    [distributorApi.reducerPath]: distributorApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(adminApi.middleware)
      .concat(distributorApi.middleware)
      .concat(orderApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
