import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";
import Logo from "../Logo";
import SearchBox from "../../components/SearchBox";
import Profile from "./Profile";
import Bag from "./Bag";
import Button from "../Button";
import { Link, NavLink } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectDistributor } from "../../app/features/distributorSlice";

interface INav {
  logo?: any;
  buttonText: string;
  btnBgColor?: string;
  handleBtnClick: () => void;
  avatar?: any;
  firstName?: string;
  userToken?: string;
  navItems?: { id: number; title: string; linkTo: string }[];
  showSearch: boolean;
  dashboardType: "store" | "distributor" | "admin";
}

const Nav = ({
  logo,
  buttonText,
  btnBgColor,
  handleBtnClick,
  avatar,
  firstName,
  userToken,
  navItems,
  showSearch,
  dashboardType,
}: INav) => {
  const { token: distributorToken } = useAppSelector(selectDistributor);
  return (
    <Container>
      <Wrapper>
        <LogoWrapper>
          <Logo logo={logo} />
        </LogoWrapper>

        <SearchWrapper showSearch={showSearch}>
          <SearchBox dashboardType={dashboardType} />
        </SearchWrapper>

        <ContentWrapper>
          {userToken && (
            <NavLinks>
              {navItems?.map((item) => (
                <NavItem key={item.id}>
                  <StyledNavLink to={item.linkTo}>{item.title}</StyledNavLink>
                </NavItem>
              ))}
            </NavLinks>
          )}

          <Profile src={avatar} firstName={firstName} token={userToken} />
          {userToken === distributorToken ? (
            <Bag />
          ) : !userToken ? (
            <Bag />
          ) : null}

          <ButtonWrapper>
            <Button
              text={buttonText}
              onClick={handleBtnClick}
              bgColor={btnBgColor}
            />
          </ButtonWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default Nav;

// Styled Components
const NavLinks = styled.ul`
  display: flex;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const StyledNavLink = styled(NavLink).attrs((props) => ({
  className: ({ isActive }: { isActive: boolean }) =>
    isActive ? "active" : "",
}))`
  text-decoration: none;
  color: ${colors.black};

  &.active {
    text-decoration: underline;
    color: ${colors.green};
    text-underline-offset: 4px;
  }

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
`;

const NavItem = styled.li`
  list-style: none;
  cursor: pointer;

  a {
    text-decoration: none;
    color: ${colors.black};
  }

  &:hover a {
    text-decoration: underline;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SearchWrapper = styled.div<{ showSearch: boolean }>`
  display: ${({ showSearch }) => (showSearch ? "flex" : "none")};
  flex: 1;
  margin: 0 2rem;

  @media (max-width: ${screens.tab}) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
    width: 50%;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
`;

const LogoWrapper = styled.div`
  display: flex;

  @media (max-width: ${screens.tab}) {
    display: none;
  }
`;
