import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../utils";

interface SmallCountdownTimerProps {
  targetDate: string;
}

interface TimeLeft {
  d: number;
  h: number;
  m: number;
  s: number;
}

const SmallCountdownTimer: React.FC<SmallCountdownTimerProps> = ({
  targetDate,
}) => {
  const calculateTimeLeft = (): TimeLeft => {
    const target = new Date(targetDate);
    const now = new Date();
    const difference = target.getTime() - now.getTime();
    let timeLeft: TimeLeft;

    if (difference > 0) {
      timeLeft = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        m: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        s: Math.floor((difference % (1000 * 60)) / 1000),
      };
    } else {
      timeLeft = {
        d: 0,
        h: 0,
        m: 0,
        s: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (
        newTimeLeft.d === 0 &&
        newTimeLeft.h === 0 &&
        newTimeLeft.m === 0 &&
        newTimeLeft.s === 0
      ) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <CountdownWrapper isCritical={timeLeft.h === 0 && timeLeft.m < 10}>
      <TimeBlock>
        <NumberWrapper>
          <Number>{String(timeLeft.d).padStart(2, "0")}</Number>
          <Label>D</Label>
        </NumberWrapper>
        <Colon>:</Colon>
      </TimeBlock>

      <TimeBlock>
        <NumberWrapper>
          <Number>{String(timeLeft.h).padStart(2, "0")}</Number>
          <Label>H</Label>
        </NumberWrapper>
        <Colon>:</Colon>
      </TimeBlock>

      <TimeBlock>
        <NumberWrapper>
          <Number>{String(timeLeft.m).padStart(2, "0")}</Number>
          <Label>M</Label>
        </NumberWrapper>
        <Colon>:</Colon>
      </TimeBlock>

      <TimeBlock>
        <NumberWrapper>
          <Number>{String(timeLeft.s).padStart(2, "0")}</Number>
          <Label>S</Label>
        </NumberWrapper>
      </TimeBlock>
    </CountdownWrapper>
  );
};

export default SmallCountdownTimer;

const CountdownWrapper = styled.div<{ isCritical: boolean }>`
  display: flex;
  gap: 0.5rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  background-color: ${(props) =>
    props.isCritical ? colors.red : colors.black};
  width: fit-content;
  height: fit-content;
`;

const TimeBlock = styled.div`
  display: flex;
  height: fit-content;
  gap: 0.5rem;
`;

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Number = styled.div`
  font-size: 0.7rem;
  color: ${colors.white};
  font-weight: bold;
`;

const Label = styled.div`
  font-size: 0.6rem;
  color: ${colors.white};
  text-transform: uppercase;
`;

const Colon = styled.div`
  font-size: 0.7rem;
  color: ${colors.white};
  font-weight: bold;
  display: flex;
`;
