import React from "react";
import { styled } from "styled-components";

const TopFinisher = () => {
  return (
    <Container>
      <Wrapper>TopFinisher</Wrapper>
    </Container>
  );
};

export default TopFinisher;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
`;
