import React from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import SignIn from "./SignIn";
import Authentication from "../../common/Authentication";
import { colors } from "../../utils";
import SignUp from "./SignUp";
import DistributorPasswordReset from "./DistributorPasswordReset";
import DistributorPasswordCode from "./DistributorPasswordCode";

const Distributor = () => {
  const location = useLocation();
  const isSignInRoute = location.pathname === "/sign-in/distributor";
  const isSignUpRoute = location.pathname === "/sign-up/distributor";
  const isPasswordCode = location.pathname === "/distributor-password-code";
  const isPasswordReset = location.pathname === "/distributor-password-reset";

  return (
    <Wrapper>
      {isSignInRoute && (
        <Authentication
          bgCol={colors.white}
          splashBgCol={colors.blue}
          contentBgCol={colors.white}
          headingCol={colors.white}
          title="Distributor Sign in"
          titleCol={colors.black}
          heading="EdificeMart, your best friend"
          subheading="The only place to buy discounted building materials on the internet"
          accountText="Don't have an account"
          accountTo="/sign-up/distributor"
          accountToText="Sign up here"
          passwordText="Forgot your password?"
          passwordTo="/distributor-password-code"
        >
          <SignIn />
        </Authentication>
      )}
      {isSignUpRoute && (
        <Authentication
          bgCol={colors.white}
          splashBgCol={colors.blue}
          contentBgCol={colors.white}
          headingCol={colors.white}
          title="Distributor Sign Up"
          titleCol={colors.black}
          heading="EdificeMart, your best friend"
          subheading="The only place to buy discounted building materials on the internet"
          accountText="Already have an account"
          accountTo="/sign-in/distributor"
          accountToText="Sign in"
        >
          <SignUp />
        </Authentication>
      )}
      {isPasswordCode && (
        <Authentication
          bgCol={colors.white}
          splashBgCol={colors.blue}
          contentBgCol={colors.white}
          headingCol={colors.white}
          title="Distributor Reset Password"
          titleCol={colors.black}
          heading="EdificeMart, your best friend"
          subheading="The only place to buy discounted building materials on the internet"
        >
          <DistributorPasswordCode />
        </Authentication>
      )}
      {isPasswordReset && (
        <Authentication
          bgCol={colors.white}
          splashBgCol={colors.blue}
          contentBgCol={colors.white}
          headingCol={colors.white}
          title="Distributor Reset Code"
          titleCol={colors.black}
          heading="EdificeMart, your best friend"
          subheading="The only place to buy discounted building materials on the internet"
        >
          <DistributorPasswordReset />
        </Authentication>
      )}
    </Wrapper>
  );
};

export default Distributor;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;
