import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useGetDistributorShippingQuery } from "../../../app/services/adminApi";
import { selectAdmin } from "../../../app/features/adminSlice";
import { Form, Input } from "../../../common/FormElements";
import { setShipping } from "../../../app/features/shippingSlice";
import { styled } from "styled-components";

function AdminDistributorShipping() {
  const navigate = useNavigate();
  const { distributorId } = useParams();
  const { token } = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();

  // Fetch distributor shipping details
  const { data, isLoading, isSuccess } =
    useGetDistributorShippingQuery(distributorId);
  const shippingAddress = data?.shippingAddress;

  // State for shipping details
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");

  // Redirect if not logged in
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [navigate, token]);

  // Prefill form data when query is successful
  useEffect(() => {
    if (isSuccess && shippingAddress) {
      setFullName(shippingAddress.fullName || "");
      setAddress(shippingAddress.address || "");
      setCity(shippingAddress.city || "");
      setPostalCode(shippingAddress.postalCode || "");
      setCountry(shippingAddress.country || "");
    }
  }, [isSuccess, shippingAddress]);

  // Submit Handler
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(setShipping({ fullName, address, city, postalCode, country }));
    // Store the shipping information in localStorage
    localStorage.setItem(
      "shipping",
      JSON.stringify({
        fullName,
        address,
        city,
        postalCode,
        country,
      })
    );

    // Navigate to the next page
    navigate(`/admin-dashboard/distributors/${distributorId}/order-details`);
  };

  // Loading state
  if (isLoading) {
    return <p>Loading shipping details...</p>;
  }

  return (
    <Container>
      <Wrapper>
        <Form onSubmit={submitHandler}>
          <h3>Shipping Address</h3>

          <div>
            <Input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Full Name"
            />

            <Input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
            />
          </div>

          <div>
            <Input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="City"
            />

            <Input
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              placeholder="Postal Code"
            />

            <Input
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Country"
            />
          </div>

          <div>
            <button>Continue</button>
          </div>
        </Form>
      </Wrapper>
    </Container>
  );
}

export default AdminDistributorShipping;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto;
  flex-direction: column;
  gap: 1.5rem;
`;
