import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useGetAllProductsQuery,
  useClearExpiredDiscountsMutation,
} from "../../../app/services/adminApi";
import Modal from "../../../common/Modal";
import TargetDateForm from "../../HomeScreens/TargetDateForm";
import DiscountManagerCard from "./DiscountManagerCard";
import Pagination from "../../../common/Pagination";

interface Product {
  _id: string;
  name: string;
  images: string[];
  brand: string;
  category: string;
  description: string;
  price: number;
  discountTime?: Date | null;
  discountCode?: string | null;
  discountPrice?: number | null;
  countInStock: number;
}

const DiscountManager = () => {
  const {
    data: products,
    isLoading: productIsLoading,
    isError: productIsError,
    error: productError,
    refetch,
  } = useGetAllProductsQuery({});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>("");

  const [expiredProductIds, setExpiredProductIds] = useState<string[]>([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 5; // Number of products to show per page

  const totalItems = products?.length || 0; // Total number of products

  // Filter products for the current page
  const paginatedProducts = products?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleAddCountdown = (productId: string) => {
    setSelectedProductId(productId);
    setIsOpen(true);
  };

  const [clearExpiredDiscounts] = useClearExpiredDiscountsMutation();

  useEffect(() => {
    const checkExpiredDiscounts = () => {
      if (products) {
        const now = new Date();
        const newlyExpiredProductIds = products
          .filter(
            (product: Product) =>
              product.discountTime && new Date(product.discountTime) < now
          )
          .map((product: Product) => product._id);

        if (
          newlyExpiredProductIds.length > 0 &&
          !areArraysEqual(newlyExpiredProductIds, expiredProductIds)
        ) {
          setExpiredProductIds(newlyExpiredProductIds);
          clearExpiredDiscounts({});
          refetch();
        }
      }
    };

    checkExpiredDiscounts();
  }, [products, expiredProductIds, clearExpiredDiscounts, refetch]);

  const areArraysEqual = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((id) => arr2.includes(id));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <Wrapper>
        {productIsLoading ? (
          <div>Loading products...</div>
        ) : productIsError ? (
          <div>Error loading products</div>
        ) : (
          paginatedProducts.map((product: Product) => (
            <DiscountManagerCard
              key={product._id}
              product={product}
              handleAddCountdown={handleAddCountdown}
            />
          ))
        )}

        {/* Pagination Component */}
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
        />
      </Wrapper>

      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <TargetDateForm
          productId={selectedProductId}
          closeModal={() => setIsOpen(false)}
        />
      </Modal>
    </Container>
  );
};

export default DiscountManager;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  gap: 2rem;
  flex-direction: column;
  margin: 2rem auto;
`;
