import React from "react";
import styled from "styled-components";

// Define types for props
interface Product {
  _id: string;
  name: string;
  images: string[];
  price: number;
}

interface OrderItem {
  _id: string;
  quantity: number;
  product: Product;
}

interface Distributor {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
}

interface ShippingAddress {
  fullName: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
}

interface InvoiceCardProps {
  distributor: Distributor;
  orderItems: OrderItem[];
  shippingAddress: ShippingAddress;
  itemsPrice: number;
  totalPrice: number;
  authorizationUrl: string;
}

const InvoiceCard: React.FC<InvoiceCardProps> = ({
  distributor,
  orderItems,
  shippingAddress,
  itemsPrice,
  totalPrice,
  authorizationUrl,
}) => {
  return (
    <Card>
      <CardHeader>Invoice Details</CardHeader>

      <Section>
        <SectionTitle>Distributor Details</SectionTitle>
        <Info>
          <InfoRow>
            <span>Name:</span>{" "}
            <strong>{`${distributor.firstName} ${distributor.lastName}`}</strong>
          </InfoRow>
          <InfoRow>
            <span>Email:</span> <strong>{distributor.email}</strong>
          </InfoRow>
          <InfoRow>
            <span>Phone:</span> <strong>{distributor.phoneNo}</strong>
          </InfoRow>
        </Info>
      </Section>

      <Section>
        <SectionTitle>Shipping Address</SectionTitle>
        <Info>
          <InfoRow>
            <span>Full Name:</span> <strong>{shippingAddress.fullName}</strong>
          </InfoRow>
          <InfoRow>
            <span>Address:</span> <strong>{shippingAddress.address}</strong>
          </InfoRow>
          <InfoRow>
            <span>City:</span> <strong>{shippingAddress.city}</strong>
          </InfoRow>
          <InfoRow>
            <span>Postal Code:</span>{" "}
            <strong>{shippingAddress.postalCode}</strong>
          </InfoRow>
          <InfoRow>
            <span>Country:</span> <strong>{shippingAddress.country}</strong>
          </InfoRow>
        </Info>
      </Section>

      <Section>
        <SectionTitle>Order Items</SectionTitle>
        <ItemsList>
          {orderItems.map((item) => (
            <Item key={item._id}>
              <ItemImage src={item.product.images[0]} alt={item.product.name} />
              <ItemDetails>
                <h4>{item.product.name}</h4>
                <p>Quantity: {item.quantity}</p>
                <p>Price: ₦{item.product.price.toLocaleString()}</p>
              </ItemDetails>
            </Item>
          ))}
        </ItemsList>
      </Section>

      <Section>
        <SectionTitle>Price Details</SectionTitle>
        <Info>
          <InfoRow>
            <span>Items Price:</span>{" "}
            <strong>₦{itemsPrice.toLocaleString()}</strong>
          </InfoRow>
          <InfoRow>
            <span>Total Price:</span>{" "}
            <strong>₦{totalPrice.toLocaleString()}</strong>
          </InfoRow>
        </Info>
      </Section>

      <PayButton onClick={() => window.open(authorizationUrl, "_blank")}>
        Pay Now
      </PayButton>
    </Card>
  );
};

export default InvoiceCard;

// Styled Components
const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CardHeader = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
`;

const Section = styled.div`
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h3`
  margin-bottom: 0.5rem;
  color: #333;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: #555;

  span {
    color: #666;
  }

  strong {
    color: #333;
  }
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Item = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const ItemImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  h4 {
    margin: 0;
    color: #222;
  }

  p {
    margin: 0;
    color: #555;
    font-size: 0.9rem;
  }
`;

const PayButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;
