import React, { useEffect, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { clearCart, selectCart } from "../../../app/features/cartSlice";
import {
  clearShipping,
  selectShipping,
} from "../../../app/features/shippingSlice";
import { styled } from "styled-components";
import { colors, typography } from "../../../utils";
import { selectAdmin } from "../../../app/features/adminSlice";
import { useCreateDistributorOrderMutation } from "../../../app/services/adminApi";
import Spinner from "../../../utils/Spinner";

const AdminDistributorOrderSummary = () => {
  const { distributorId } = useParams();
  const { admin } = useAppSelector(selectAdmin);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cart = useAppSelector(selectCart);
  const shipping = useAppSelector(selectShipping);
  const [createOrder, { isLoading, isError }] =
    useCreateDistributorOrderMutation();

  const adminId = admin.id;

  // Helper function to round numbers
  const round2 = (num: any) => Math.round(num * 100 + Number.EPSILON) / 100;

  // Calculate Items Price using discountPrice if it exists
  const itemsPrice = round2(
    cart.cartItems.reduce(
      (a, c) => a + c.quantity * (c.product.discountPrice ?? c.product.price),
      0
    )
  );

  // Total price without shipping and VAT
  const totalPrice = itemsPrice;

  const generateOrderId = (): string => {
    return `order_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  };

  const placeOrderHandler = async () => {
    try {
      const orderId = generateOrderId();
      const newOrder = {
        id: orderId,
        orderItems: cart.cartItems.map((item: any) => ({
          quantity: item.quantity,
          product: item.product._id as string,
        })),
        shippingAddress: {
          fullName: shipping.fullName,
          address: shipping.address,
          city: shipping.city,
          postalCode: shipping.postalCode,
          country: shipping.country,
        },
        itemsPrice,
        totalPrice,
        distributor: distributorId,
        admin: adminId,
      };

      const result = await createOrder(newOrder).unwrap();
      dispatch(clearCart());
      dispatch(clearShipping());
      navigate(`/admin-dashboard/payments/${distributorId}/${orderId}`);
      toast.success("Order successfully placed");
    } catch (error) {
      toast.error("Something went wrong!!!");
    }
  };

  return (
    <OrderReviewContainer>
      <h3>Preview Order</h3>

      <div className="placeholder">
        <div className="placeholder-content">
          <div className="section">
            <h4>Shipping</h4>

            <div className="placeholder-text">
              <div>
                <strong>Name:</strong> <p>{shipping.fullName}</p>
              </div>

              <div>
                <strong>Address:</strong>{" "}
                <p>
                  {shipping.address}, {shipping.city}, {shipping.postalCode},{" "}
                  {shipping.country}
                </p>
              </div>

              <LinkTo
                to={`/admin-dashboard/distributors/${distributorId}/shipping`}
              >
                Edit
              </LinkTo>
            </div>
          </div>

          <div className="section">
            <h4>Items in Order</h4>

            <div className="product-wrapper">
              {cart.cartItems.map((item) => (
                <div key={item.product._id}>
                <img src={item.product.images[0]} alt={item.product.name} />

                  <LinkProd
                    to={`/admin-dashboard/distributors/${distributorId}/place-order/${item.product._id}`}
                  >
                    {item.product.name}
                  </LinkProd>

                  <p>{item.quantity}</p>

                  <p>
                    {(
                      item.product.discountPrice ?? item.product.price
                    ).toLocaleString("en-NG", {
                      style: "currency",
                      currency: "NGN",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              ))}
            </div>
            <LinkTo to="/admin-dashboard/cart">Edit</LinkTo>
          </div>
        </div>

        <div className="order-summary">
          <h4>Order Summary</h4>

          {
            <Grid>
              <strong>Items:</strong>
              <p>
                {itemsPrice.toLocaleString("en-NG", {
                  style: "currency",
                  currency: "NGN",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>

              <strong>Total:</strong>
              <p>
                {totalPrice.toLocaleString("en-NG", {
                  style: "currency",
                  currency: "NGN",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Grid>
          }

          <button
            onClick={placeOrderHandler}
            disabled={cart.cartItems.length === 0}
          >
           {isLoading ? <Spinner color={colors.C1I} /> : "Place Order"}
          </button>
          {/* <small>
            Payment is protected by Escrow. Seller will not receive payment
            until you confirm that your order has been fulfilled.
          </small> */}
        </div>
      </div>
    </OrderReviewContainer>
  );
};

export default AdminDistributorOrderSummary;

const OrderReviewContainer = styled.div`
  display: flex;
  width: 90%;
  margin: auto;
  flex-direction: column;

  h3 {
    margin: 2rem auto;
    font: ${typography.S3};
    color: ${colors.C1A};
  }

  .placeholder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .placeholder-content {
      display: flex;
      flex-direction: column;
      width: 75%;

      .section {
        border-bottom: 1px solid ${colors.C2E};
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;

        h4 {
          font: ${typography.S4};
          color: ${colors.C2A};
          margin-bottom: 0.5rem;
        }

        div {
          margin-bottom: 0.4rem;

          strong {
            font: ${typography.Body};
            color: ${colors.C1B};
            font-weight: 600;
            margin-right: 0.5rem;
          }

          p {
            font: ${typography.Body};
            color: ${colors.C1B};
          }
        }

        .product-wrapper {
          display: flex;
          width: 100%;
          flex-direction: column;
          // height: 40vh;
          // overflow: scroll;

          button {
            margin-left: 0;
          }

          div {
            width: 95%;
            display: grid;
            grid-template-columns: 0.3fr 1.4fr 0.5fr 1.2fr;
            column-gap: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid ${colors.C2E};
            padding-bottom: 0.5rem;

            img {
              height: 4rem;
              width: 3.5rem;
              border-radius: 0.25rem;
              overflow: hidden;
              object-fit: contain;
              -o-object-fit: contain;
              border: 1px solid ${colors.C2D};
            }

            p {
              font: ${typography.Body};
              color: ${colors.C1B};
              margin-right: 0.5rem;
            }
          }
        }
      }
    }

    .order-summary {
      display: flex;
      flex-direction: column;
      width: 25%;

      h4 {
        font: ${typography.S4};
        color: ${colors.C2A};
        margin-bottom: 0.5rem;
      }

      p {
        font: ${typography.Body};
        margin-bottom: 1rem;
      }

      button {
        outline: none;
        border-radius: 0.25rem;
        border: none;
        background: ${colors.C2A};
        color: ${colors.C1H};
        font: ${typography.Body};
        height: 2.2rem;
        width: 100%;
        cursor: pointer;
        margin: 0 auto;

        &:hover {
          background: ${colors.C2B};
          transform: background 0.2s ease-in-out;
        }

        &:disabled {
          background-color: ${colors.C2D};
          cursor: not-allowed;
        }
      }

      small {
        font-size: 0.5rem;
        margin-top: 0.6rem;
      }
    }
  }
`;

const LinkTo = styled(Link)`
  text-decoration: none;
  font: ${typography.Caption};
  color: ${colors.C1B};
  display: flex;
  height: fit-content;
  width: fit-content;
  padding: 0.1rem 0.4rem;
  background: ${colors.C5D};
  border-radius: 0.25rem;
  margin: 0.8rem 0;
`;

const LinkProd = styled(Link)`
  text-decoration: none;
  font: ${typography.Body};
  color: ${colors.C1B};
  display: flex;
  text-transform: capitalize;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.2rem;

  p {
    font: ${typography.Body};
    color: ${colors.C1B};
  }

  strong {
    font: ${typography.Body};
    color: ${colors.C1B};
    font-weight: 700;
  }
`;
