import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Fuse from "fuse.js";
import { styled } from "styled-components";
import { useDistributorGetAllProductsQuery } from "../../app/services/distributorApi";
import { colors, typography } from "../../utils";
import search from "../../assets/images/search.svg";

// Define the Product interface
interface Product {
  _id: string;
  name: string;
  description: string;
  // Any other fields that your product object has
}

interface ISearchBox {
  dashboardType: "store" | "distributor" | "admin";
}

const SearchBox = ({ dashboardType }: ISearchBox) => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  // Fetch all products from the API
  const { data: products, isLoading, isError } = useDistributorGetAllProductsQuery({});

  // Fuse.js options
  const fuseOptions = {
    keys: ["name", "description"],
    threshold: 0.3,
  };

  // Initialize Fuse with the correct Product type
  const fuse = new Fuse<Product>(products || [], fuseOptions);

  // Determine the path based on the dashboard type
  const generatePath = (productId: string) => {
    switch (dashboardType) {
      case "store":
        return `/search-results`;
      case "distributor":
        return `/distributor-dashboard/search-results`;
      case "admin":
        return `/admin-dashboard/search-results`;
      default:
        return `/search-results`;
    }
  };

  // Handle search submission
  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Perform fuzzy search using Fuse.js
    const results = fuse.search(searchQuery);

    // Extract only the product data from the results
    const filteredProducts = results.map((result) => ({
      ...result.item as Product, 
      path: generatePath(result.item._id),
    }));

    // Navigate to the appropriate SearchResults page based on dashboardType
    navigate(generatePath(""), {
      state: { filteredProducts, query: searchQuery },
    });
  };

  return (
    <Container onSubmit={handleSearchSubmit}>
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={
          isLoading
            ? "Loading..."
            : isError
            ? "Error fetching data..."
            : products
            ? "Search products..."
            : ""
        }
      />
      <button type="submit">
        <img src={search} alt="" />
      </button>
    </Container>
  );
};

export default SearchBox;

// Styled components
const Container = styled.form`
  outline: none;
  display: flex;
  border: 1.5px solid ${colors.C2C};
  border-radius: 0.3rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.9rem;
  width: 100%;

  input {
    outline: none;
    border: none;
    display: flex;
    flex: 1;

    &::placeholder {
      font: ${typography.Body};
    }
  }

  button {
    display: flex;
    height: fit-content;
    padding: 0.2rem;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: ${colors.C2E};
    }
  }

  img {
    height: 1rem;
  }
`;
