import DistributorLayout from "../../layouts/DistributorLayout";
import { Outlet } from "react-router-dom";

const DistributorPages = () => {
  return (
    <DistributorLayout>
      <Outlet />
    </DistributorLayout>
  );
};

export default DistributorPages;
