import React, { ChangeEvent } from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";

interface IInput {
  type?: string;
  value?: any;
  name?: string;
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
}

const Input = ({
  type,
  value,
  name,
  placeholder,
  onChange,
  accept,
}: IInput) => {
  return (
    <Container
      type={type}
      value={value}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      accept={accept}
    />
  );
};

export default Input;

const Container = styled.input`
  outline: none;
  font-size: 1rem;
  padding: 0.6rem;
  border: 1px solid ${colors.grey}; 
  background-color: transparent;
  border-radius: 0.25rem;
  width: 100%;
  color: ${colors.black};

  ::placeholder {
    color: ${colors.grey};
    opacity: 0.7;
  }

  @media (max-width: ${screens.tab}) {
    /* You can add styles here for responsive design */
  }
`;
