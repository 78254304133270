import React from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import SignIn from "./SignIn";
import Authentication from "../../common/Authentication";
import { colors } from "../../utils";
import AdminPasswordCode from "./AdminPasswordCode";
import AdminPasswordReset from "./AdminPasswordReset";

const Admin = () => {
  const location = useLocation();
  const isSignInRoute = location.pathname === "/sign-in/admin";
  const isPasswordCode = location.pathname === "/admin-password-code";
  const isPasswordReset = location.pathname === "/admin-password-reset";

  return (
    <Wrapper>
      {isSignInRoute && (
        <Authentication
          bgCol={colors.white}
          splashBgCol={colors.blue}
          contentBgCol={colors.white}
          headingCol={colors.white}
          title="Admin Sign in"
          titleCol={colors.black}
          heading="EdificeMart, your best friend"
          subheading="The only place to buy discounted building materials on the internet"
          accountText="Forgot your password?"
          accountTo="/admin-password-code"
          accountToText="Update it here"
        >
          <SignIn />
        </Authentication>
      )}
      {isPasswordCode && (
        <Authentication
          bgCol={colors.white}
          splashBgCol={colors.blue}
          contentBgCol={colors.white}
          headingCol={colors.white}
          title="Admin Reset Password"
          titleCol={colors.black}
          heading="EdificeMart, your best friend"
          subheading="The only place to buy discounted building materials on the internet"
        >
          <AdminPasswordCode />
        </Authentication>
      )}
      {isPasswordReset && (
        <Authentication
          bgCol={colors.white}
          splashBgCol={colors.blue}
          contentBgCol={colors.white}
          headingCol={colors.white}
          title="Admin Reset Code"
          titleCol={colors.black}
          heading="EdificeMart, your best friend"
          subheading="The only place to buy discounted building materials on the internet"
        >
          <AdminPasswordReset />
        </Authentication>
      )}
    </Wrapper>
  );
};

export default Admin;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;
