

export const typography = {
    S1: {
        fontFamily: 'Poppins',
        fontSize: '2rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 1.4
    },

    S2: {
        fontFamily: 'Poppins',
        fontSize: '1.625rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 1.5
    },

    S3: {
        fontFamily: 'Poppins',
        fontSize: '1.375rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 1.5
    },

    S4: {
        fontFamily: 'Poppins',
        fontSize: '1.125rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 1.5
    },

    S5: {
        fontFamily: 'Poppins',
        fontSize: '0.9375rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 1.5
    },

    S6: {
        fontFamily: 'Poppins',
        fontSize: '0.75rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 1.5
    },

    Button: {
        fontFamily: 'Poppins',
        fontSize: '0.9375rem',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 1.5
    },

    Lead: {
        fontFamily: 'Open Sans',
        fontSize: '1.0625rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 1.5

    },

    Body: {
        fontFamily: 'Open Sans',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 1.375
    },

    Caption: {
        fontFamily: 'Open Sans',
        fontSize: '0.75rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 1.375
    },

    Small: {
        fontFamily: 'Open Sans',
        fontSize: '0.6rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 1.375
    }


}