import React, { FormEvent, useEffect, useState } from "react";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { useAddAdminMutation } from "../../../app/services/adminApi";
import { CustomError } from "../../../../types";
import { Button, Form, Input, Label } from "../../../common/FormElements";
import { colors } from "../../../utils";
import Select from "../../../common/FormElements/Select";
import Spinner from "../../../utils/Spinner";

interface IAddAdmin {
  closeModal: () => void;
}

const tagData = [
  {
    optionText: "CEO",
    optionValue: "ceo",
  },

  {
    optionText: "Manager",
    optionValue: "manager",
  },

  {
    optionText: "Customer Care",
    optionValue: "customer-care",
  },
];

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  tag: "",
};

const AddAdmin = ({ closeModal }: IAddAdmin) => {
  const [formValue, setFormValue] = useState(initialState);
  const [addAdmin, { isLoading, data, isSuccess, isError, error }] =
    useAddAdminMutation();

  const { firstName, lastName, email, phoneNo, tag } = formValue;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (firstName && lastName && email && phoneNo && tag) {
      await addAdmin({
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNo: phoneNo,
        tag: tag,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Admin added successfully");
      closeModal();
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Add a new Admin</h3>
        <Form onSubmit={handleSubmit}>
          <Label text="Admin's first name" />
          <Input
            type="text"
            value={firstName}
            name="firstName"
            onChange={handleInputChange}
          />
          <Label text="Admin's last name" />
          <Input
            type="text"
            value={lastName}
            name="lastName"
            onChange={handleInputChange}
          />
          <Label text="Admin's email" />
          <Input
            type="email"
            value={email}
            name="email"
            onChange={handleInputChange}
          />
          <Label text="Admin's phone no" />
          <Input
            type="text"
            value={phoneNo}
            name="phoneNo"
            onChange={handleInputChange}
          />

          <ItemWrapper>
            <Label text="Position" fontSize="1rem" color={colors.dark} />
            <Select
              value={tag}
              name="tag"
              defaultOption="Select position"
              onChange={handleInputChange}
              options={tagData}
            />
          </ItemWrapper>

          <Button
            color={colors.black}
            type="submit"
            border="none"
            backgroundColor={colors.yellow}
            text={isLoading ? <Spinner color={colors.black} /> : "Add Admin"}
            disabled={isLoading}
          />
        </Form>
      </Wrapper>
    </Container>
  );
};

export default AddAdmin;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.6rem;
  margin: auto;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
