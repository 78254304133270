import React, { useState } from "react";
import { styled } from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { selectAdmin } from "../../app/features/adminSlice";
import { colors, screens } from "../../utils";
import { Button } from "../../common/FormElements";
import productImage from "../../assets/images/discount.png";
import topImage from "../../assets/images/TopFinisherTransparent.png";
import DashboardHeading from "../../common/DashboardHeading";
import { useNavigate } from "react-router-dom";
import DashboardCard from "../DistributorScreens/DashboardHome/DashboardCard";
import { LuShoppingBag } from "react-icons/lu";
import { LiaShuttleVanSolid } from "react-icons/lia";
import { BsPerson } from "react-icons/bs";
import { MdOutlinePayments } from "react-icons/md";
import {
  useGetAllAdminsQuery,
  useGetAllDistributorsQuery,
  useGetAllPaymentsQuery,
  useGetAllProductsQuery,
} from "../../app/services/adminApi";

const SUMMARY_DATA = [
  {
    id: 1,
    title: "Products",
    count: "products",
    icon: "products",
    linkTo: "/admin-dashboard/products",
    backgroundColor: "#E2FBD7",
  },
  {
    id: 2,
    title: "Payments",
    count: "payments",
    icon: "payments",
    linkTo: "/admin-dashboard/payments",
    backgroundColor: "#FABFA0",
  },
  {
    id: 3,
    title: "Staff",
    count: "staff",
    icon: "staff",
    linkTo: "/admin-dashboard/admins",
    backgroundColor: "#BEDAD8",
  },
  {
    id: 4,
    title: "Distributors",
    count: "distributors",
    icon: "distributors",
    linkTo: "/admin-dashboard/distributors",
    backgroundColor: "#E2FBD7",
  },
];

const AdminScreens = () => {
  const navigate = useNavigate();
  const { admin } = useAppSelector(selectAdmin);
  const { data: products } = useGetAllProductsQuery({});
  const { data: distributors } = useGetAllDistributorsQuery({});
  const { data: adminStaff } = useGetAllAdminsQuery({});
  const { data: adminPayments } = useGetAllPaymentsQuery({});

  const handleDiscountManager = () => {
    navigate("/admin-dashboard/discount-manager");
  };

  const handleTopFinisher = () => {
    navigate(`/admin-dashboard/distributors`);
  };

  return (
    <Container>
      <Wrapper>
        <DashboardHeading
          text="This is the Admin Dashboard. Manage your Products and Distributors here"
          firstName={admin.firstName}
        />

        <CardWrapper>
          {SUMMARY_DATA.map((item) => {
            return (
              <DashboardCard
                key={item.id}
                linkTo={item.linkTo}
                title={item.title}
                count={
                  item.count === "products"
                    ? `${products ? products.length : 0}`
                    : item.count === "payments"
                    ? `${adminPayments ? adminPayments.totalSum : 0}`
                    : item.count === "staff"
                    ? `${adminStaff ? adminStaff.length : 0}`
                    : item.count === "distributors"
                    ? `${distributors ? distributors.length : 0}`
                    : null
                }
                backgroundColor={item.backgroundColor}
                icon={
                  item.icon === "products" ? (
                    <LuShoppingBag color={colors.black} size="1rem" />
                  ) : item.icon === "staff" ? (
                    <BsPerson color={colors.black} size="1rem" />
                  ) : item.icon === "payments" ? (
                    <MdOutlinePayments color={colors.black} size="1rem" />
                  ) : item.icon === "distributors" ? (
                    <LiaShuttleVanSolid color={colors.black} size="1rem" />
                  ) : null
                }
              />
            );
          })}
        </CardWrapper>
        <ContentWrapper>
          <TextContent>
            <h3>Top Finisher</h3>
            <p>Shop and win amazing prizes.</p>
            <ButtonWrapper>
              <Button
                text="Shop for Distributor"
                color={colors.black}
                backgroundColor="transparent"
                border={`1px solid ${colors.black}`}
                onClick={handleTopFinisher}
              />
            </ButtonWrapper>
          </TextContent>
          <ImageWrapper>
            <img src={topImage} alt="" />
          </ImageWrapper>
        </ContentWrapper>
        <ContentWrapper>
          <TextContent>
            <h3>Discount Manager</h3>
            <p>Select Product and set discounts for each of them.</p>
            <ButtonWrapper>
              <Button
                text="Go to Discount Manager"
                color={colors.black}
                backgroundColor="transparent"
                border={`1px solid ${colors.black}`}
                onClick={handleDiscountManager}
              />
            </ButtonWrapper>
          </TextContent>
          <ImageWrapper>
            <img src={productImage} alt="" />
          </ImageWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default AdminScreens;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 1.5rem;
  margin: 1rem auto;

  h2 {
    color: ${colors.black};
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.5rem;
  gap: 2rem;
  margin: 1rem auto;
  background-color: ${colors.blueBack};
  height: fit-content;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 10rem;
  overflow: hidden;
  border-radius: 0 0.5rem 0.5rem 0;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

const TextContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }

  p {
    font-size: 0.8rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;
