import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../utils";

interface IAuthCard {
  title: string;
  linkTo: string;
}

const AuthCard = ({ title, linkTo }: IAuthCard) => {
  return (
    <Container to={linkTo}>
      <Wrapper>
        <TextWrapper>
          <p>{title}</p>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};

export default AuthCard;

const Container = styled(Link)`
  display: flex;
  width: fit-content;
  border-radius: 0.5rem;
  background-color: ${colors.C1E};
  text-decoration: none;
`;

const Wrapper = styled.div`
  align-items: center;
  margin: 1rem 1rem 1rem 1.5rem;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  width: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;

  p {
    font-size: 0.8rem;
    color: ${colors.black};
  }
`;
