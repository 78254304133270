import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useUpdatePasswordMutation } from "../../app/services/adminApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CustomError } from "../../../types";
import { selectAdmin, setAdmin } from "../../app/features/adminSlice";
import PasswordReset from "../../common/Authentication/PasswordReset";

const AdminPasswordReset = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { admin } = useAppSelector(selectAdmin);
  const [updatePassword, { data, isSuccess, isLoading, isError, error }] =
    useUpdatePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Code sent to your email");
      dispatch(
        setAdmin({
          id: data.existingAdmin._id,
          firstName: data.existingAdmin.firstName,
          lastName: data.existingAdmin.lastName,
          email: data.existingAdmin.email,
          phoneNo: data.existingAdmin.phoneNo,
          tag: data.existingAdmin.tag,
          token: data.token,
        })
      );
      navigate("/admin-dashboard/home");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess]);

  return (
    <Container>
      <PasswordReset
        isLoading={isLoading}
        updatePassword={updatePassword}
        email={admin.email}
      />
    </Container>
  );
};

export default AdminPasswordReset;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
