import { styled } from "styled-components";
import { colors } from "../colors";
import { typography } from "../typography";

interface IQuantity {
  countInStock: string;
}

const QuantityTag = ({ countInStock }: IQuantity) => {
  return (
    <Container>
      <small>{countInStock}</small>
    </Container>
  );
};

export default QuantityTag;

const Container = styled.div`
  color: ${colors.C1H};
  background-color: ${colors.C2C};
  border-radius: 0.625rem;
  display: flex;

  small {
    font-size: ${typography.Caption};
    margin: 0.05rem 0.25rem;
    color: ${colors.C1H};
  }
`;
