import React, { useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectDistributor } from "../../../app/features/distributorSlice";
import Modal from "../../../common/Modal";
import AddDistributorStaff from "./AddDistributorStaff";
import { Button } from "../../../common/FormElements";
import { colors, screens } from "../../../utils";
import { styled } from "styled-components";
import AllDistributorStaff from "./AllDistributorStaff";

const DistributorStaff = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { distributor } = useAppSelector(selectDistributor);

  const handleAddDistributor = () => {
    setIsOpen(true);
  };

  return (
    <Container>
      <Wrapper>
        <h3>All Staff</h3>
        <AllDistributorStaff />
        {distributor.tag === "owner" && (
          <AddDistributorWrapper>
            <h3>Onboard a New Distributor</h3>
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
              <AddDistributorStaff closeModal={() => setIsOpen(false)} />
            </Modal>
            <Button
              type="button"
              color={colors.black}
              backgroundColor={colors.yellow}
              border="none"
              onClick={handleAddDistributor}
              text="Onboard a Distributor"
            />
          </AddDistributorWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

export default DistributorStaff;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  gap: 2rem;
  flex-direction: column;
  margin: 2rem auto;
`;

const AddDistributorWrapper = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    padding: 0;
    border-left: none;
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.dark};
  }
`;
