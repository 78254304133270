import React from "react";
import styled from "styled-components";
import { colors, screens } from "../../../utils";
import SmallCountdownTimer from "./SmallCountdownTimer";
import { Button } from "../../../common/FormElements";

interface DiscountManagerCardProps {
  product: any;
  handleAddCountdown: (productId: string) => void;
}

interface PriceProps {
  hasDiscount: boolean;
}

const DiscountManagerCard: React.FC<DiscountManagerCardProps> = ({
  product,
  handleAddCountdown,
}) => {
  return (
    <ProductCard>
      <ProductInfo>
        <TitleContainer>
          <h4>{product.name}</h4>
        </TitleContainer>

        <Content>
          <DiscountWrapper>
            <PriceContainer>
              <Price hasDiscount={!!product.discountPrice}>
                {product.price.toLocaleString("en-NG", {
                  style: "currency",
                  currency: "NGN",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Price>
              {product.discountPrice && (
                <DiscountPrice>
                  {product.discountPrice.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </DiscountPrice>
              )}
            </PriceContainer>
            {product.discountCode && (
              <DiscountCode>{product.discountCode}% Off</DiscountCode>
            )}
          </DiscountWrapper>
          {product.discountTime && (
            <SmallCountdownTimer
              targetDate={new Date(product.discountTime).toISOString()}
            />
          )}
        </Content>
      </ProductInfo>
      <ButtonWrapper>
        <Button
          text={product.discountTime ? "Update Timer" : "Create Discount"}
          onClick={() => handleAddCountdown(product._id)}
          border="none"
          color={colors.black}
          backgroundColor={colors.yellow}
        />
      </ButtonWrapper>
    </ProductCard>
  );
};

export default DiscountManagerCard;

const ProductCard = styled.div`
  border: 1px solid ${colors.black};
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: ${colors.white};
  display: flex;
  width: 100%;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  gap: 1rem;
  flex: 1;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  width: 50%;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }

  h4 {
    font-weight: 500;
    color: ${colors.black};
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const DiscountWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`;

const Price = styled.p<PriceProps>`
  font-size: 1rem;
  color: ${(props) => (props.hasDiscount ? colors.grey : colors.black)};
  text-decoration: ${(props) => (props.hasDiscount ? "line-through" : "none")};
`;

const DiscountPrice = styled.p`
  font-size: 0.8rem;
  color: ${colors.black};
  font-weight: 500;
`;

const DiscountCode = styled.p`
  font-size: 0.8rem;
  color: ${colors.white};
  font-weight: 600;
  padding: 0.1rem 0.2rem;
  background-color: ${colors.green};
  border-radius: 0.25rem;
  display: flex;
  width: fit-content;
  height: fit-content;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
`;
