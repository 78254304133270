import { styled } from "styled-components";
import { FaCircle } from "react-icons/fa";
import { typography } from "../typography";
import { colors } from "../colors";

interface IStatusTag {
  tagColor?: string;
  text?: string;
}

const StatusesTag = ({ tagColor, text }: IStatusTag) => {
  return (
    <Container>
      <Wrapper>
        <FaCircle
          color={tagColor}
          width="0.5rem"
          style={{ display: "flex", margin: 0, width: "0.5rem", padding: 0, }}
        />
        <p>{text}</p>
      </Wrapper>
    </Container>
  );
};

export default StatusesTag;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.3rem;
  align-items: center;
  margin: 0 !important;
  padding: 0;

  p {
    font: ${typography.Caption};
    color: ${colors.C1B};
    margin: 0;
    display: flex;
  }
`;
