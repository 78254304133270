import React from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";

interface IPassword {
  type: string;
  value: string | number;
  name: string;
  placeholder?: string;
  passwordType: string;
  onChange: any;
  onClick: () => void;
  accept?: any;
}

const PasswordInput = ({
  type,
  value,
  name,
  placeholder,
  passwordType,
  onChange,
  onClick,
}: IPassword) => {
  return (
    <PasswordInputContainer>
      <StyledInput
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
      <IconWrapper onClick={onClick}>
        {passwordType === "password" ? (
          <AiOutlineEye color={colors.grey} />
        ) : (
          <AiOutlineEyeInvisible color={colors.grey} />
        )}
      </IconWrapper>
    </PasswordInputContainer>
  );
};

export default PasswordInput;

const PasswordInputContainer = styled.div`
  display: flex !important;
  align-items: center;
  outline: none;
  font-size: 1rem;
  padding: 0.6rem;
  border: 1px solid ${colors.grey};
  border-radius: 0.25rem;
  width: 100%;
  background-color: transparent;

  @media (max-width: ${screens.tab}) {
    /* Responsive styles */
  }
`;

const StyledInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0 0.6rem;
  font-size: 1rem;
  flex: 1;
  color: ${colors.black};

  ::placeholder {
    color: ${colors.grey};
    opacity: 0.7;
  }

  @media (max-width: ${screens.tab}) {
    font-size: 1rem; /* Responsive font size */
  }
`;

const IconWrapper = styled.span`
  border-left: 1px solid ${colors.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.6rem;
  cursor: pointer;
  background-color: transparent;
`;
