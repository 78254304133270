import React from "react";
import { useGetPaymentsByDistributorQuery } from "../../../app/services/adminApi";
import styled from "styled-components";
import { colors } from "../../../utils";

const ByDistributor = () => {
  const { data, isLoading, isSuccess, isError } =
    useGetPaymentsByDistributorQuery({});

  return (
    <Wrapper>
      <TitleWrapper>
        <h2>Payments by Distributors</h2>
      </TitleWrapper>

      {isLoading && <p>Loading payments...</p>}
      {isError && <p>Failed to load payments. Please try again later.</p>}

      {isSuccess && data?.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Distributor Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Total Sum (₦)</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: any, index: any) => (
              <tr key={index}>
                <td>
                  {item.distributor.firstName} {item.distributor.lastName}
                </td>
                <td>{item.distributor.email}</td>
                <td>{item.distributor.phoneNo}</td>
                <td>₦{item.totalSum.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {isSuccess && data?.length === 0 && (
        <p>No payments found for distributors.</p>
      )}
    </Wrapper>
  );
};

export default ByDistributor;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  margin: 1rem auto;

  h2 {
    color: ${colors.black};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.4rem;
    text-align: left;
    border: 1px solid ${colors.C2D};
  }

  th {
    background-color: ${colors.C2E};
    color: ${colors.black};
    font-size: 1rem;
    font-weight: 500;
  }

  td {
    color: ${colors.black};
    font-size: 0.8rem;
  }
`;
