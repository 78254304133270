import React, { ChangeEvent, useState } from "react";
import { styled } from "styled-components";
import { Button, Input, Label, PasswordInput } from "../../FormElements";
import PasswordCheck from "../../../utils/PasswordCheck";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";

interface IPasswordReset {
  isLoading: boolean;
  email: string | undefined;
  updatePassword: any;
}

const initialState = {
  password: "",
  confirmPassword: "",
  passCode: "",
};

const PasswordReset = ({
  isLoading,
  email,
  updatePassword,
}: IPasswordReset) => {
  const [formValue, setFormValue] = useState(initialState);
  const { passCode, password, confirmPassword } = formValue;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  //Toggle Password visibility
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    console.log("ENER");

    if (password && passCode) {
      await updatePassword({
        password,
        passCode,
        email,
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <PasswordWrapper>
        <Label text="Password Reset Code" />
        <Input
          type="text"
          name="passCode"
          value={passCode}
          onChange={handleInputChange}
        />
        <Label text="New Password" />
        <PasswordInput
          type={passwordType}
          name="password"
          value={password}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />
        <Label text="Confirm Password" />
        <PasswordInput
          type={passwordType}
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />
      </PasswordWrapper>

      <PasswordCheck password={password} />

      <Button
        color={colors.black}
        type="submit"
        border="none"
        backgroundColor={colors.yellow}
        text={isLoading ? <Spinner color={colors.black} /> : "Reset Password"}
        disabled={isLoading}
      />
    </Form>
  );
};

export default PasswordReset;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;

  /* div {
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
  } */
`;

const PasswordWrapper = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
  gap: 0.5rem;
  :last-of-type {
    margin-bottom: 0 !important;
  }
`;
