import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { screens } from "../../utils";

interface LogoProps {
  logo: string;
}

function Logo({ logo }: LogoProps) {
  return (
    <Container to="/">
      <LogoImage src={logo} alt="Logo" />
    </Container>
  );
}

export default Logo;

const Container = styled(Link)`
  height: 2rem;
  overflow: hidden;

  @media (max-width: ${screens.tab}) {
    //display: none;
  }
`;

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
