import React from "react";
import styled from "styled-components";
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import { colors } from "./colors";

interface PasswordCheckProps {
  password: string;
}

const timesIcon = <FaTimesCircle color={colors.red} size={8} />;
const checkIcon = <FaCheckCircle color={colors.green} size={8} />;

const PasswordCheck: React.FC<PasswordCheckProps> = ({ password }) => {
  const [uppercase, setUppercase] = React.useState(false);
  const [numberChar, setNumberChar] = React.useState(false);
  const [specialChar, setSpecialChar] = React.useState(false);
  const [passwordLength, setPasswordLength] = React.useState(false);

  React.useEffect(() => {
    //check uppercase
    if (password.match(/[A-Z]/) && password.match(/[a-z]/)) {
      setUppercase(true);
    } else {
      setUppercase(false);
    }

    //check number
    if (password.match(/[0-9]/)) {
      setNumberChar(true);
    } else {
      setNumberChar(false);
    }

    //Check Special character
    if (password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
      setSpecialChar(true);
    } else {
      setSpecialChar(false);
    }

    //check password length
    if (password.length >= 6) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }
  }, [password]);

  return (
    <PasswordCheckContainer>
      <PasswordCheckItem>
        <PasswordCheckIcon>{switchIcon(uppercase)}</PasswordCheckIcon>
        <PasswordCheckLabel>At least 1 uppercase letter</PasswordCheckLabel>
      </PasswordCheckItem>
      <PasswordCheckItem>
        <PasswordCheckIcon>{switchIcon(numberChar)}</PasswordCheckIcon>
        <PasswordCheckLabel>At least 1 number</PasswordCheckLabel>
      </PasswordCheckItem>
      <PasswordCheckItem>
        <PasswordCheckIcon>{switchIcon(specialChar)}</PasswordCheckIcon>
        <PasswordCheckLabel>At least 1 special character</PasswordCheckLabel>
      </PasswordCheckItem>
      <PasswordCheckItem>
        <PasswordCheckIcon>{switchIcon(passwordLength)}</PasswordCheckIcon>
        <PasswordCheckLabel>At least 6 characters long</PasswordCheckLabel>
      </PasswordCheckItem>
    </PasswordCheckContainer>
  );
};

//Swtich icon for password strength test
const switchIcon = (c: any) => {
  if (c) {
    return checkIcon;
  } else return timesIcon;
};

export default PasswordCheck;

const PasswordCheckContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PasswordCheckItem = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;
`;

const PasswordCheckLabel = styled.span`
  margin-right: 0.75rem;
  font-size: 0.6rem;
`;

const PasswordCheckIcon = styled.span`
  margin-right: 0.3rem;
  align-items: center;
  justify-content: center;
  display: flex;
`;
