import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

//Base URL
const url = process.env.REACT_APP_BASE_URI as string;

export const distributorApi = createApi({
  reducerPath: "distributorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}distributors/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state.distributor.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Distributor", "Product"],
  endpoints: (builder) => ({
    // Login a Distributor
    loginDistributor: builder.mutation({
      query: (data) => {
        return {
          url: "distributor-signin",
          method: "POST",
          body: data,
        };
      },
    }),

    // Create a new Distributor
    signupDistributor: builder.mutation({
      query: (data) => {
        return {
          url: "distributor-signup",
          method: "POST",
          body: data,
        };
      },
    }),

    // Add a Distributor
    addDistributor: builder.mutation({
      query: (data) => ({
        url: "add-distributor",
        method: "POST",
        body: data,
      }),
    }),

    // Get distributors
    getAllDistributors: builder.query({
      query: () => "distributors",
      providesTags: ["Distributor"],
    }),

    // Generate Password Reset Code
    passwordCode: builder.mutation({
      query: (email) => ({
        url: "/password-code",
        method: "POST",
        body: email,
      }),
    }),

    // Update Password with Reset Code
    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/password-reset",
        method: "POST",
        body: data,
      }),
    }),

    // Get a single distributor by ID
    getADistributorById: builder.query({
      query: (id) => `distributors/${id}`,
      providesTags: ["Distributor"],
    }),

    // Delete an distributor by ID
    deleteDistributorById: builder.mutation({
      query: (id) => ({
        url: `distributors/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Distributor"],
    }),

    // Update distributor details
    updateDistributorById: builder.mutation({
      query: ({ id, data }) => ({
        url: `distributors/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Distributor"],
    }),

    // Get all products
    distributorGetAllProducts: builder.query({
      query: () => "products",
      providesTags: ["Product"],
    }),

    // Get a single product by ID
    distributorGetProductById: builder.query({
      query: (id) => `products/${id}`,
      providesTags: ["Product"],
    }),

    // Add a Distributor
    addArtisan: builder.mutation({
      query: (data) => ({
        url: "create-artisan",
        method: "POST",
        body: data,
      }),
    }),

    // Get all artisans for a distributor
    distributorGetAllArtisans: builder.query({
      query: () => "distributor-artisans",
      providesTags: ["Distributor"],
    }),

    // Get order and payment data for a distributor
    getOrderAndPaymentForDistributor: builder.query({
      query: (id) => `distributor/${id}/orders-payments`,
      providesTags: ["Distributor"],
    }),

     // Get distributor payments
     getDistributorPayments: builder.query({
      query: (id) => `${id}/get-orders-and-payments`,
      providesTags: ["Distributor"],
    }),

    // Get All Staff
    getAllStaff: builder.query({
      query: (id) => `${id}/all-staff`,
      providesTags: ["Distributor"],
    }),

    //...
  }),
});

export const {
  usePasswordCodeMutation,
  useUpdatePasswordMutation,
  useAddDistributorMutation,
  useDeleteDistributorByIdMutation,
  useDistributorGetAllProductsQuery,
  useDistributorGetProductByIdQuery,
  useGetADistributorByIdQuery,
  useGetAllDistributorsQuery,
  useLoginDistributorMutation,
  useUpdateDistributorByIdMutation,
  useSignupDistributorMutation,
  useAddArtisanMutation,
  useDistributorGetAllArtisansQuery,
  useGetOrderAndPaymentForDistributorQuery,
  useGetDistributorPaymentsQuery,
  useGetAllStaffQuery,
} = distributorApi;
