import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectDistributor } from "../../../app/features/distributorSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectShipping,
  setShipping,
} from "../../../app/features/shippingSlice";
import { Form, Input } from "../../../common/FormElements";
import { styled } from "styled-components";

function DistributorShipping() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token, distributor } = useAppSelector(selectDistributor);
  const {
    fullName: existingFullName,
    address: existingAddress,
    city: existingCity,
    postalCode: existingPostalCode,
    country: existingCountry,
  } = useAppSelector(selectShipping);

  const profile = `${distributor.firstName} ${distributor.lastName}`;

  const [fullName, setFullName] = useState(existingFullName || profile || "");
  const [address, setAddress] = useState(existingAddress || "");
  const [city, setCity] = useState(existingCity || "");
  const [postalCode, setPostalCode] = useState(existingPostalCode || "");
  const [country, setCountry] = useState(existingCountry || "");

  //Keep user out of this screen when logged out
  useEffect(() => {
    if (!token) {
      navigate("/signin");
    }
  }, [navigate, token]);

  //Submit Handler
  const submitHandler = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    dispatch(setShipping({ fullName, address, city, postalCode, country }));

    // Store the shipping information in localStorage
    localStorage.setItem(
      "shipping",
      JSON.stringify({
        fullName,
        address,
        city,
        postalCode,
        country,
      })
    );

    // Send user to payment page
    navigate("/distributor-dashboard/order-details");
  };

  return (
    <Container>
      <Wrapper>
        <Form onSubmit={submitHandler}>
          <h3>Shipping Address</h3>

          <div>
            <Input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Full Name"
            />

            <Input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
            />
          </div>

          <div>
            <Input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="City"
            />

            <Input
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              placeholder="Postal Code"
            />

            <Input
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Country"
            />
          </div>

          <div>
            <button>Continue</button>
          </div>
        </Form>
      </Wrapper>
    </Container>
  );
}

export default DistributorShipping;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto;
  flex-direction: column;
  gap: 1.5rem;
`;
