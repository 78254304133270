import React from "react";
import styled from "styled-components";
import { colors, screens } from "../../utils"; 

interface IButton {
  onClick?: () => void;
  text: any;
  borderRadius?: string;
  color?: string;
  bgColor?: string;
  border?: string;
  type?: any;
}

// Updated Button Component to pass all styling props to Container
const Button = ({ onClick, text, borderRadius, color, bgColor, border, type }: IButton) => {
  return (
    <Container 
      onClick={onClick} 
      borderRadius={borderRadius} 
      color={color} 
      bgColor={bgColor} 
      border={border}
      type={type}
    >
      {text}
    </Container>
  );
};

export default Button;

// Corrected Container styled component with all props being used
const Container = styled.button<{
  borderRadius?: string;
  color?: string;
  bgColor?: string;
  border?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;      
  width: fit-content;
  border-radius: ${(props) => props.borderRadius || "0.25rem"};
  color: ${(props) => props.color || colors.black};
  font-weight: 500;
  background-color: ${(props) => props.bgColor || colors.yellow};
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: ${(props) => props.border || "none"};
  cursor: pointer;

  @media (max-width: ${screens.tab}) {
    width: 100%; 
  }

  &:hover {
    opacity: 0.9;
  }
`;
