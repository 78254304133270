import React, { useState, useEffect } from "react";
import {
  useUpdateAdminByIdMutation,
  useGetAdminByIdQuery,
} from "../../app/services/adminApi";
import { useParams } from "react-router-dom";
import { colors, screens } from "../../utils";
import { Form, Input, Label } from "../../common/FormElements";
import Select from "../../common/FormElements/Select";
import Button from "../../common/Button";
import { styled } from "styled-components";

const UpdateAdmin = () => {
  const { id: adminId } = useParams();
  const { data: admin, error, isLoading } = useGetAdminByIdQuery(adminId);
  const [updateAdminById] = useUpdateAdminByIdMutation();

  // State for form inputs
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    tag: "",
  });

  // Prefill form fields when admin data is available
  useEffect(() => {
    if (admin) {
      setFormData({
        firstName: admin.firstName || "",
        lastName: admin.lastName || "",
        email: admin.email || "",
        phoneNo: admin.phoneNo || "",
        tag: admin.tag || "",
      });
    }
  }, [admin]);

  // Handle form input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateAdminById({ id: adminId, data: formData });
      alert("Admin updated successfully!");
    } catch (error) {
      console.error("Error updating admin:", error);
      alert("Failed to update admin");
    }
  };

  if (isLoading) return <p>Loading admin data...</p>;
  if (error) return <p>Error loading admin data</p>;

  // Options for the tag select input
  const tagOptions = [
    { optionValue: "ceo", optionText: "CEO" },
    { optionValue: "manager", optionText: "Manager" },
    { optionValue: "customer-care", optionText: "Customer Care" },
  ];

  return (
    <Container>
      <Wrapper>
        <h3>Update this Admin</h3>
        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            <ItemWrapper>
              <Label text="First Name" />
              <Input
                type="text"
                name="firstName"
                value={formData.firstName}
                placeholder="Enter first name"
                onChange={handleChange}
              />
            </ItemWrapper>

            <ItemWrapper>
              <Label text="Last Name" />
              <Input
                type="text"
                name="lastName"
                value={formData.lastName}
                placeholder="Enter last name"
                onChange={handleChange}
              />
            </ItemWrapper>

            <ItemWrapper>
              <Label text="Email" />
              <Input
                type="email"
                name="email"
                value={formData.email}
                placeholder="Enter email"
                onChange={handleChange}
              />
            </ItemWrapper>

            <ItemWrapper>
              <Label text="Phone Number" />
              <Input
                type="text"
                name="phoneNo"
                value={formData.phoneNo}
                placeholder="Enter phone number"
                onChange={handleChange}
              />
            </ItemWrapper>

            <ItemWrapper>
              <Label text="Tag" />
              <Select
                name="tag"
                value={formData.tag}
                onChange={handleChange}
                defaultOption="Select Tag"
                options={tagOptions}
              />
            </ItemWrapper>

            <Button
              type="submit"
              text="Update Admin"
              color={colors.white}
              bgColor={colors.green}
            />
          </Form>
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};

export default UpdateAdmin;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 50%;
  margin: 1rem auto;
  gap: 1rem;
  flex-direction: column;
  gap: 1rem;

  h3 {
    font-size: 1.3rem;
    font-weight: 500;
    color: ${colors.black};
  }

  @media (max-width: ${screens.tab}) {
    width: 90%;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.8rem;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
