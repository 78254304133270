import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

//Base URL
const url = process.env.REACT_APP_BASE_URI as string;

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}admins/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state.admin.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Admin", "Distributor"],
  endpoints: (builder) => ({
    // Login a Partner
    loginAdmin: builder.mutation({
      query: (data) => {
        return {
          url: "admin-signin",
          method: "POST",
          body: data,
        };
      },
    }),

    // Add an Admin
    addAdmin: builder.mutation({
      query: (data) => ({
        url: "add-admin",
        method: "POST",
        body: data,
      }),
    }),

    // Get admins
    getAllAdmins: builder.query({
      query: () => "admins",
      providesTags: ["Admin"],
    }),

    // Generate Password Reset Code
    passwordCode: builder.mutation({
      query: (email) => ({
        url: "/password-code",
        method: "POST",
        body: email,
      }),
    }),

    // Update Password with Reset Code
    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/password-reset",
        method: "POST",
        body: data,
      }),
    }),

    // Get a single admin by ID
    getAdminById: builder.query({
      query: (id) => `admins/${id}`,
      providesTags: ["Admin"],
    }),

    // Delete an admin by ID
    deleteAdminById: builder.mutation({
      query: (id) => ({
        url: `admins/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),

    // Update admin details
    updateAdminById: builder.mutation({
      query: ({ id, data }) => ({
        url: `admins/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Admin"],
    }),

    // Create a new product
    createProduct: builder.mutation({
      query: (data) => ({
        url: "add-product",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Admin"],
    }),

    // Get all products
    getAllProducts: builder.query({
      query: () => "products",
      providesTags: ["Admin"],
    }),

    // Get a single product by ID
    getProductById: builder.query({
      query: (id) => `products/${id}`,
      providesTags: ["Admin"],
    }),

    // Update a product by ID
    updateProductById: builder.mutation({
      query: ({ id, data }) => ({
        url: `products/${id}/update`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Admin"],
    }),

    // Delete a product by ID
    deleteProductById: builder.mutation({
      query: (id) => ({
        url: `products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),

    // Add a Distributor
    addADistributor: builder.mutation({
      query: (data) => ({
        url: "add-distributor",
        method: "POST",
        body: data,
      }),
    }),

    // Get all Distributprs
    getAllDistributors: builder.query({
      query: () => "distributors",
      providesTags: ["Distributor"],
    }),

    // Get a single admin by ID
    getADistributorById: builder.query({
      query: (id) => `distributors/${id}`,
      providesTags: ["Distributor"],
    }),

    // Add discount code and countdown to product
    addDiscount: builder.mutation({
      query: (data) => ({
        url: "products/discount-countdown",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Admin"],
    }),

    clearExpiredDiscounts: builder.mutation({
      query: () => ({
        url: "clearExpiredDiscounts",
        method: "POST",
      }),
    }),

    // Get distributor shipping address
    getDistributorShipping: builder.query({
      query: (id) => `${id}/shipping-address`,
      providesTags: ["Distributor"],
    }),

    //Create a new order
    createDistributorOrder: builder.mutation({
      query: (newOrder) => ({
        url: "order-for-distributor",
        method: "POST",
        body: newOrder,
      }),
    }),

    // Get all orders
    getAllOrders: builder.query({
      query: () => `get-all-orders`,
      providesTags: ["Distributor"],
    }),

    // Get a single order by Id
    getOneOrder: builder.query({
      query: ({ orderId }) => `get-orders/${orderId}`,
      providesTags: ["Distributor"],
    }),

    // Get all raffle codes
    getAllRaffleCodes: builder.query({
      query: () => "raffle-codes",
    }),

    // Get all payments
    getAllPayments: builder.query({
      query: () => "get-all-payments",
      providesTags: ["Distributor"],
    }),

    // Get payment data for a single distributor
    getPaymentsByDistributor: builder.query({
      query: () => `get-payments-by-distributors`,
      providesTags: ["Distributor"],
    }),

    // Add a Top Finisher Admin
    addTopFinisher: builder.mutation({
      query: (data) => ({
        url: "add-top-finisher",
        method: "POST",
        body: data,
      }),
    }),

    //...
  }),
});

export const {
  useAddAdminMutation,
  useLoginAdminMutation,
  useGetAllAdminsQuery,
  usePasswordCodeMutation,
  useUpdatePasswordMutation,
  useGetAdminByIdQuery,
  useUpdateAdminByIdMutation,
  useDeleteAdminByIdMutation,
  useCreateProductMutation,
  useGetAllProductsQuery,
  useGetProductByIdQuery,
  useUpdateProductByIdMutation,
  useDeleteProductByIdMutation,
  useAddADistributorMutation,
  useGetADistributorByIdQuery,
  useGetAllDistributorsQuery,
  useAddDiscountMutation,
  useClearExpiredDiscountsMutation,
  useGetDistributorShippingQuery,
  useCreateDistributorOrderMutation,
  useGetAllOrdersQuery,
  useGetOneOrderQuery,
  useGetAllRaffleCodesQuery,
  useGetAllPaymentsQuery,
  useGetPaymentsByDistributorQuery,
  useAddTopFinisherMutation,
} = adminApi;
