import React from "react";
import { useParams } from "react-router-dom";
import { useGetInvoiceByIdQuery } from "../../../app/services/orderApi";
import styled from "styled-components";
import InvoiceCard from "../../../common/InvoiceCard";

const AdminDistributorInvoiceDetails = () => {
  const { distributorId, orderId } = useParams();
  const { data, isLoading, isError } = useGetInvoiceByIdQuery({ orderId, distributorId });

  if (isLoading) return <Message>Loading...</Message>;
  if (isError || !data) return <Message>Something went wrong. Please try again.</Message>;

  return (
    <Container>
      <Wrapper>
        <InvoiceCard
          distributor={data.distributor}
          orderItems={data.orderItems}
          shippingAddress={data.shippingAddress}
          itemsPrice={data.itemsPrice}
          totalPrice={data.totalPrice}
          authorizationUrl={data.authorizationUrl}
        />
      </Wrapper>
    </Container>
  );
};

export default AdminDistributorInvoiceDetails;

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

const Message = styled.div`
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
  color: #666;
`;
