import React from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectAdmin } from "../../../app/features/adminSlice";
import ProductsList from "../../Products/ProductsList";
import { styled } from "styled-components";
import { useGetAllProductsQuery } from "../../../app/services/adminApi";
import { colors } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import AdminProductsCard from "./AdminProductsCard";

const AdminPlaceOrderForDistributor = () => {
  const { id: distributorId } = useParams();
  const { admin } = useAppSelector(selectAdmin);
  const adminId = admin.id;

  const { data, isLoading, isError } = useGetAllProductsQuery({});

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.primary} />
        ) : isError ? (
          <p>Oops! There was an error</p>
        ) : (
          data.map((product: any) => {
            return (
              <AdminProductsCard
                key={product._id}
                product={product}
                to={product._id}
              />
            );
          })
        )}
      </Wrapper>
    </Container>
  );
};

export default AdminPlaceOrderForDistributor;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  row-gap: 2rem;
  margin: 2rem auto;
  flex-wrap: wrap;
`;
