import React, { useState, ChangeEvent, FormEvent } from "react";
import { useAddArtisanMutation } from "../../../app/services/distributorApi";
import { toast } from "react-hot-toast";
import { styled } from "styled-components";
import { Button, Input, Label } from "../../../common/FormElements";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";

const AddArtisan: React.FC = () => {
  const [addArtisan, { isLoading: isAdding }] = useAddArtisanMutation();

  const [formData, setFormData] = useState<{
    fullName: string;
    email: string;
    phoneNo: string;
    quantity: number;
    amount: number;
  }>({
    fullName: "",
    email: "",
    phoneNo: "",
    quantity: 0,
    amount: 0,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:
        name === "quantity" || name === "amount" ? parseInt(value) : value,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await addArtisan(formData).unwrap();
      toast.success("Artisan added successfully!");
      setFormData({
        fullName: "",
        email: "",
        phoneNo: "",
        quantity: 0,
        amount: 0,
      });
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h2>Add a New Artisan</h2>
        <InputWrapper>
          <Label text="Artisan's Full Name" />
          <Input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
          />
        </InputWrapper>

        <InputWrapper>
          <Label text="Artisan's Email" />
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </InputWrapper>

        <InputWrapper>
          <Label text="Artisan's Phone No" />
          <Input
            type="text"
            name="phoneNo"
            value={formData.phoneNo}
            onChange={handleChange}
          />
        </InputWrapper>

        <InputWrapper>
          <Label text="Order Quantity" />
          <Input
            type="number"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
          />
        </InputWrapper>

        <InputWrapper>
          <Label text="Order Amount" />
          <Input
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
          />
        </InputWrapper>

        <Button
          type="submit"
          disabled={isAdding}
          text={isAdding ? <Spinner color={colors.C2E} /> : "Add Artisan"}
          backgroundColor={colors.C2B}
          color={colors.C2E}
          border="none"
        />
      </Form>
    </Container>
  );
};

export default AddArtisan;

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 50%;

  @media (max-width: ${screens.tab}) {
    width: 90%;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
`;
