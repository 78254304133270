import { Link } from "react-router-dom";
import { styled } from "styled-components";
import image from "../../assets/icons/cart.svg";
import { useAppSelector } from "../../app/hooks";
import { selectCart } from "../../app/features/cartSlice";
import { colors, typography } from "../../utils";
import { selectDistributor } from "../../app/features/distributorSlice";

function Bag() {
  const cart = useAppSelector(selectCart);
  const { token } = useAppSelector(selectDistributor);
  return (
    <Container to={token ? "/distributor-dashboard/cart" : "cart"}>
      <Wrapper>
        <ImageWrapper>
          <Image src={image} alt="cart" />
        </ImageWrapper>

        {cart.cartItems.length > 0 && (
          <CartContent>
            <CartSpan>
              <small>
                {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
              </small>
            </CartSpan>
          </CartContent>
        )}
      </Wrapper>
    </Container>
  );
}

export default Bag;

const Container = styled(Link)`
  display: flex;
  text-decoration: none;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const CartContent = styled.div`
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: ${colors.C4A};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.7rem;
  right: 0.8rem;
  padding: auto;
`;

const CartSpan = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;

  small {
    font: ${typography.Small};
    display: flex;
    text-align: center;
    padding: 0;
    margin: auto;
    color: ${colors.C1E};
  }
`;
