import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProductByIdQuery } from "../../../app/services/adminApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addToCart, selectCart } from "../../../app/features/cartSlice";
import { toast } from "react-hot-toast";
import { styled } from "styled-components";
import { colors, screens, typography } from "../../../utils";
import SmallCountdownTimer from "../DiscountManager/SmallCountdownTimer";
import QuantityTag from "../../../utils/QuantityTag";
import { Button } from "../../../common/FormElements";
import commentImage from "../../../assets/images/logo-thumbnail.png";
import Spinner from "../../../utils/Spinner";

interface PriceProps {
  hasDiscount: boolean;
}

const AdminOrderProductDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cart = useAppSelector(selectCart);
  const { distributorId, productId } = useParams();
  const [itemAdded, setItemAdded] = useState(false);
  const { data, isLoading, isError } = useGetProductByIdQuery(productId);

  const addToCartHandler = () => {
    const product = data;
    const quantity = 1;

    // Check if the product ID matches the currently displayed product
    if (product._id === data._id) {
      // Find the cart item for the current product
      const existingCartItem = cart.cartItems.find(
        (item) => item.product._id === product._id
      );

      // Check if countInStock is defined and adding the specified quantity exceeds the countInStock
      if (
        data.countInStock !== undefined &&
        existingCartItem &&
        existingCartItem.quantity + quantity > data.countInStock
      ) {
        // Handle the case where adding more items than available in stock is not allowed
        toast.error(
          `Sorry, there are not enough items in stock! Available: ${data.countInStock}`
        );
      } else {
        // Dispatch the addToCart action if the quantity is within the available stock
        dispatch(addToCart({ product, quantity, distributorId }));
        setItemAdded(true);

        if (!itemAdded) {
            navigate("/admin-dashboard/cart", { state: { distributorId } });
        }
      }
    } else {
      // Handle the case where the product ID doesn't match the currently displayed product
      toast.error("Cannot add this item to the cart.");
    }
  };

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.primary} />
        ) : isError ? (
          <p>Oops! Something went wrong.</p>
        ) : data ? (
          <>
            <ImageWrapper>
              <Image src={data.images[0]} alt={data.name} />
            </ImageWrapper>

            <ContentContainer>
              <Title>{data.name}</Title>

              <PricesWrapper>
                <DiscountWrapper>
                  <Price hasDiscount={!!data.discountPrice}>
                    Price:{" "}
                    {data.price.toLocaleString("en-NG", {
                      style: "currency",
                      currency: "NGN",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Price>
                  <PriceContainer>
                    {data.discountPrice && (
                      <DiscountPrice>
                        {data.discountPrice.toLocaleString("en-NG", {
                          style: "currency",
                          currency: "NGN",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </DiscountPrice>
                    )}
                    {data.discountCode && (
                      <DiscountCode>{data.discountCode}% Off</DiscountCode>
                    )}
                  </PriceContainer>
                </DiscountWrapper>

                {data.discountTime && (
                  <CounterWrapper>
                    <h5>Discount expires in:</h5>
                    <SmallCountdownTimer
                      targetDate={new Date(data.discountTime).toISOString()}
                    />
                  </CounterWrapper>
                )}
              </PricesWrapper>

              <BottomWrapper>
                <ContentWrap>
                  <h4>Quantity Available:</h4>
                  <QuantityTag countInStock={data.countInStock} />
                </ContentWrap>

                <ContentWrap>
                  <h4>Status:</h4>
                  <StatusText primary={data.countInStock > 0}>
                    <small>
                      {data.countInStock > 0 ? "In Stock" : "Unavailable"}
                    </small>
                  </StatusText>
                </ContentWrap>
              </BottomWrapper>

              <Description>{data.description}</Description>

              <ButtonWrapper>
                {data.countInStock === 0 ? null : (
                  <Button
                    onClick={addToCartHandler}
                    text="Add to Cart"
                    backgroundColor={colors.C2A}
                    color={colors.C1H}
                    border="none"
                  />
                )}
              </ButtonWrapper>

              <CommentContainer>
                <CommentImage src={commentImage} alt="comment" />
                <Comment>
                  This transaction is protected by escrow. 100% buyer protection
                  is guaranteed.
                </Comment>
              </CommentContainer>
            </ContentContainer>
          </>
        ) : null}
      </Wrapper>
    </Container>
  );
};

export default AdminOrderProductDetails;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 35%;
  height: 30rem;
  padding: 1rem;
  background-color: ${colors.C1H};
  border-radius: 0.25rem;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);

  @media Screen and (max-width: ${screens.lgTab}) {
    height: 25rem;
  }

  @media Screen and (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ContentContainer = styled.div`
  background-color: ${colors.C1I};
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const Title = styled.h2`
  font-size: ${typography.S4};
  color: ${colors.C1A};
  text-transform: capitalize;
`;

const PricesWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  //align-items: center;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentWrap = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;

  h4 {
    font-size: ${typography.Body};
  }
`;

const Description = styled.p`
  font-size: ${typography.Body};
  color: ${colors.C1B};
  margin-top: 1.5rem;
`;

const StatusText = styled.div<{ primary: boolean }>`
  background-color: ${(props) => (props.primary ? colors.C2C : colors.C4A)};
  border-radius: 0.625rem;
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;

  small {
    font-size: ${typography.Caption};
    color: ${colors.C1H};
    margin: 0.1rem 0.25rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const CommentContainer = styled.div`
  display: flex;
  align-items: center;
  border-top: 2px solid ${colors.C1E};
  padding-top: 1rem;
  margin-top: 2rem;
`;

const CommentImage = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: contain;
`;

const Comment = styled.p`
  font-size: ${typography.Caption};
  color: ${colors.C1C};
  margin-left: 1rem;
`;

const DiscountWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Price = styled.p<PriceProps>`
  font-size: 1rem;
  color: ${(props) => (props.hasDiscount ? colors.grey : colors.black)};
  text-decoration: ${(props) => (props.hasDiscount ? "line-through" : "none")};
`;

const DiscountPrice = styled.p`
  font-size: 0.8rem;
  color: ${colors.black};
  font-weight: 500;
`;

const DiscountCode = styled.p`
  font-size: 0.8rem;
  color: ${colors.white};
  font-weight: 600;
  padding: 0.1rem 0.2rem;
  background-color: ${colors.green};
  border-radius: 0.25rem;
  display: flex;
  width: fit-content;
  height: fit-content;
`;

const CounterWrapper = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 0.1rem;

  h5 {
    font-size: 0.6rem;
    color: ${colors.black};
    font-weight: 500;
  }
`;
