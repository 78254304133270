import React, { useState, ChangeEvent, FormEvent } from "react";
import { useDistributorGetAllArtisansQuery } from "../../../app/services/distributorApi";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { colors, typography } from "../../../utils";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../common/FormElements";

// Define types for artisan data
interface Artisan {
  _id: string;
  fullName: string;
  email: string;
  phoneNo: string;
  orders: Order[];
  createdAt: string;
}

interface Order {
  _id: string;
  quantity: number;
  amount: number;
  raffleCode: string;
}

const DistributorArtisans: React.FC = () => {
  const navigate = useNavigate();
  const {
    data: artisans,
    isLoading: isFetching,
    isError,
  } = useDistributorGetAllArtisansQuery({});

  // Format date
  const formatOrderDate = (createdAt: string) => {
    const orderDate = new Date(createdAt);
    const options = {
      year: "numeric" as const,
      month: "short" as const,
      day: "numeric" as const,
    };
    return orderDate.toLocaleDateString(undefined, options);
  };

  const handleClick = () => {
    navigate("add-artisan");
  };

  return (
    <Container>
      <Wrapper>
        {/* Display Artisans */}
        <h2>Artisans List</h2>
        <ButtonWrapper>
          <Button
            onClick={handleClick}
            text="Add Artisan"
            color={colors.white}
            backgroundColor={colors.C2C}
            border="none"
          />
        </ButtonWrapper>
        {isFetching ? (
          <p>Loading artisans...</p>
        ) : isError ? (
          <p>Failed to load artisans.</p>
        ) : (
          <ArtisansList>
            {artisans?.map((artisan: Artisan) => (
              <ArtisanContainer key={artisan._id}>
                <h3>{artisan.fullName}</h3>
                <p>
                  <strong>Email:</strong> {artisan.email}
                </p>
                <p>
                  <strong>Phone:</strong> {artisan.phoneNo}
                </p>
                <OrderList>
                  {artisan.orders.map((order) => (
                    <OrderCard key={order._id} to={`#`}>
                      <div className="order">
                        <p>
                          <strong>Raffle Code:</strong> {order.raffleCode}
                        </p>
                      </div>
                      <div className="order">
                        <p>
                          <strong>Quantity:</strong> {order.quantity}
                        </p>
                      </div>
                      <div className="price">
                        <p>
                          <strong>Amount:</strong>{" "}
                          {order.amount.toLocaleString("en-NG", {
                            style: "currency",
                            currency: "NGN",
                          })}
                        </p>
                      </div>
                      <div className="date">
                        <p>
                          <strong>Created At:</strong>{" "}
                          {formatOrderDate(artisan.createdAt)}
                        </p>
                      </div>
                    </OrderCard>
                  ))}
                </OrderList>
              </ArtisanContainer>
            ))}
          </ArtisansList>
        )}
      </Wrapper>
    </Container>
  );
};

export default DistributorArtisans;

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

const ArtisansList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ArtisanContainer = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
`;

const OrderList = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OrderCard = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid ${colors.C1D};
  background-color: ${colors.C2E};
  border-radius: 4px;
  text-decoration: none;

  p {
    margin: 0;
    font: ${typography.Caption};
    color: ${colors.C1B};

    strong {
      font: ${typography.Caption};
      color: ${colors.C1B};
      font-weight: 600;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: 1rem 0;
`;
