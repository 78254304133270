import { Link, useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { IoTrashOutline } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
  selectCart,
  addToCart,
} from "../../../app/features/cartSlice";
import { colors, screens, typography } from "../../../utils";
import { toast } from "react-hot-toast";
import { useState } from "react";

const AdminDistributorCart = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const distributorId = location.state?.distributorId;
  const dispatch = useAppDispatch();
  const cart = useAppSelector(selectCart);

  const [quantityInput, setQuantityInput] = useState<{
    [key: string]: number;
  }>({});

  const removeItemHandler = (item: any) => {
    dispatch(removeFromCart(item.product._id));
  };

  const updateCartHandler = (item: any, quantity: number) => {
    if (quantity > 0 && quantity <= item.product.countInStock) {
      const diff = quantity - item.quantity;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          dispatch(incrementQuantity(item.product._id));
        }
      } else if (diff < 0) {
        for (let i = 0; i < Math.abs(diff); i++) {
          dispatch(decrementQuantity(item.product._id));
        }
      }

      // Update the local state to reflect the new quantity
      setQuantityInput((prev) => ({
        ...prev,
        [item.product._id]: quantity,
      }));
    } else {
      toast.error(
        `Quantity must be between 1 and ${item.product.countInStock}`
      );
    }
  };

  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: any
  ) => {
    const value = Number(e.target.value);
    if (!isNaN(value) && value > 0 && value <= item.product.countInStock) {
      setQuantityInput((prev) => ({ ...prev, [item.product._id]: value }));
      updateCartHandler(item, value);
    }
  };

  const checkoutHandler = () => {
    if (distributorId) {
      navigate(`/admin-dashboard/distributors/${distributorId}/shipping`);
    } else {
      toast.error("Distributor is required");
    }
  };

  return (
    <Container>
      <CartItemsWrapper>
        <h2>Items in your Cart</h2>
        {cart.cartItems.length === 0 ? (
          <CartEmpty>
            <h4>Cart is empty</h4>
            <CartLink
              to={`/admin-dashboard/distributors/${distributorId}/place-order`}
            >
              Go to shop
            </CartLink>
          </CartEmpty>
        ) : (
          <CartItems>
            {cart.cartItems.map((item: any) => (
              <CartItem key={item.product._id}>
                <TitleWrapper>
                  <ImageWrapper>
                    <Image src={item.product.images[0]} alt="" />
                  </ImageWrapper>
                </TitleWrapper>

                <Title
                  to={`/admin-dashboard/distributors/${distributorId}/place-order/${item.product._id}`}
                >
                  <h4>{item.product.name}</h4>
                </Title>

                <QuantityWrapper>
                  <ButtonWrapper>
                    <button
                      disabled={item.quantity === 1}
                      onClick={() => updateCartHandler(item, item.quantity - 1)}
                    >
                      -
                    </button>

                    <QuantityInput
                      type="number"
                      value={quantityInput[item.product._id] || item.quantity}
                      onChange={(e) => handleQuantityChange(e, item)}
                      min={1}
                      max={item.product.countInStock}
                    />

                    <button
                      disabled={item.quantity === item.product.countInStock}
                      onClick={() => updateCartHandler(item, item.quantity + 1)}
                    >
                      +
                    </button>
                  </ButtonWrapper>
                </QuantityWrapper>

                <Price>
                  {item.product.discountPrice
                    ? item.product.discountPrice.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : item.product.price.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Price>

                <Price>
                  {(
                    (item.product.discountPrice
                      ? item.product.discountPrice
                      : item.product.price) * item.quantity
                  ).toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Price>

                <Delete>
                  <button onClick={() => removeItemHandler(item)}>
                    <IoTrashOutline />
                  </button>
                </Delete>
              </CartItem>
            ))}
          </CartItems>
        )}
      </CartItemsWrapper>

      <CartSummary>
        <h3>
          You have {cart.cartItems.reduce((a, c) => a + c.quantity, 0)} (items)
          :
          {cart.cartItems
            .reduce(
              (a, c) =>
                a + c.quantity * (c.product.discountPrice || c.product.price),
              0
            )
            .toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
        </h3>

        <button
          onClick={checkoutHandler}
          disabled={cart.cartItems.length === 0}
        >
          Proceed to Checkout
        </button>
      </CartSummary>
    </Container>
  );
};

export default AdminDistributorCart;

// Styled Components
const QuantityInput = styled.input`
  width: fit-content;
  height: 1.2rem;
  text-align: center;
  border: 1px solid ${colors.C1D};
  border-radius: 4px;
  font: ${typography.Body};
  margin: 0 0.5rem;
  padding: 0.3rem;

  &:focus {
    outline: none;
    border: 1px solid ${colors.C2A};
  }
`;

const Container = styled.div`
  display: flex;
  width: 90%;
  margin: auto;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const CartItemsWrapper = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;

  h2 {
    margin-bottom: 2rem;
    font: ${typography.S4};
    color: ${colors.C1B};
  }
`;

const CartItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60vh;
  overflow: Scroll;

  @media (max-width: ${screens.tab}) {
    height: fit-content;
  }
`;

const CartItem = styled.div`
  display: flex;
  margin-bottom: 2rem;
  border-bottom: 1px solid ${colors.C1F};
  padding-bottom: 1rem;
  flex-wrap: wrap;
  gap: 0.6rem;
`;

const TitleWrapper = styled.div`
  display: flex;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 0.5rem;
  margin-right: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 2rem;
    height: 2rem;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
`;

const Title = styled(Link)`
  margin: 0;
  text-decoration: none;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    font: ${typography.Body};
    color: ${colors.C1A};
    font-weight: 500;
    text-transform: capitalize;
    line-height: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    width: 1.2rem;
    height: 1.2rem;
    background-color: ${colors.C2C};
    border: none;
    border-radius: 20%;
    cursor: pointer;
    font: ${typography.Body};
    font-weight: 500;

    &:disabled {
      background-color: ${colors.C2D};
      cursor: not-allowed;
    }
  }

  span {
    margin: 0 1rem;
    align-items: center;
    justify-content: center;
    display: flex;
    font: ${typography.Body};
    font-weight: 500;
  }
`;

const Price = styled.div`
  font: ${typography.Body};
  font-weight: 500;
  width: 15%;
`;

const Delete = styled.div`
  display: flex;

  button {
    height: fit-content;
    margin: 0 auto;
    background: transparent;
    border: none;
    cursor: pointer;

    img {
      width: 1.2rem;
    }
  }
`;

const CartEmpty = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20vh;
  align-items: center;
  justify-content: center;

  h4 {
    font: ${typography.Body};
    color: ${colors.C1A};
    margin-bottom: 1.2rem;
  }
`;

const CartLink = styled(Link)`
  font: ${typography.Body};
  text-decoration: none;
`;

const CartSummary = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font: ${typography.S5};
    color: ${colors.C1A};
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  button {
    outline: none;
    border-radius: 0.25rem;
    border: none;
    background: ${colors.C2A};
    color: ${colors.C1H};
    height: 2.2rem;
    cursor: pointer;

    &:hover {
      background: ${colors.C2B};
      transform: background 0.2s ease-in-out;
    }

    &:disabled {
      background-color: ${colors.C2D};
      cursor: not-allowed;
    }
  }
`;
