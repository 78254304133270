import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../utils";
import { useAddDiscountMutation } from "../../app/services/adminApi";
import Spinner from "../../utils/Spinner";
import Button from "../../common/Button";

interface TargetDateFormProps {
  productId: string;
  closeModal: () => void;
}

const TargetDateForm: React.FC<TargetDateFormProps> = ({
  productId,
  closeModal,
}) => {
  const [targetDate, setTargetDate] = useState<string>("");
  const [discountCode, setDiscountCode] = useState<string>("");
  const [addDiscount, { isLoading, isError, isSuccess, error }] =
    useAddDiscountMutation();
  const [showTimer, setShowTimer] = useState<boolean>(false);

  const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetDate(e.target.value);
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (targetDate && discountCode && productId) {
      // No need to adjust for timezone offset since datetime-local input returns local time
      const localDate = new Date(targetDate).toISOString();

      // Submit the discount details with the local time
      await addDiscount({
        productId,
        discountCode,
        discountTime: localDate,
      });
      setShowTimer(true);
      closeModal();
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="date">Target Date:</Label>
        <Input
          type="datetime-local"
          id="date"
          value={targetDate}
          onChange={handleChangeDate}
          required
        />

        <Label htmlFor="code">Discount Code (%Discount):</Label>
        <Input
          type="text"
          id="code"
          value={discountCode}
          onChange={handleChangeCode}
          required
          placeholder="E.g 20%"
        />

        <Button
          type="submit"
          text={isLoading ? <Spinner color={colors.grey} /> : "Start Countdown"}
          border="none"
          color={colors.black}
          bgColor={colors.yellow}
          borderRadius="0.25rem"
        />
      </Form>
    </Container>
  );
};

export default TargetDateForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const Label = styled.label`
  font-size: 1rem;
  color: ${colors.black};
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
`;
