import React from "react";
import { styled } from "styled-components";
import Button from "../../../common/Button";
import { colors, screens } from "../../../utils";
import { useNavigate } from "react-router-dom";
import ProductsList from "../../Products/ProductsList";

const AdminProducts = () => {
  const navigate = useNavigate();

  const handleAddProduct = () => {
    navigate("create-product");
  };

  return (
    <Container>
      <Wrapper>
        <Button
          text="Add a Product"
          color={colors.black}
          bgColor={colors.yellow}
          onClick={handleAddProduct}
        />
        <h1>All Products</h1>
        <ProductsList dashboardType="admin" itemsPerPage={6} />
      </Wrapper>
    </Container>
  );
};

export default AdminProducts;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  flex-direction: column;
  gap: 1rem;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  width: 100%;

  /* Media query for tablet screens and below */
  @media (max-width: ${screens.tab}) {
    grid-template-columns: 1fr;
  }
`;
