import React from "react";
import { styled } from "styled-components";
import { colors } from "../../../utils";
import ProductsList from "../../Products/ProductsList";

const HomeStore = () => {
  return (
    <Container>
      <Wrapper>
        <Content>
          <ProductTitle>Product Store</ProductTitle>
          <ProductsList dashboardType="store" itemsPerPage={10} />
        </Content>
      </Wrapper>
    </Container>
  );
};

export default HomeStore;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-direction: column;
  margin: 0rem auto 2rem auto;
`;

const Content = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto 2rem auto;
  flex-direction: column;
  gap: 1.5rem;
`;

const ProductTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${colors.black};
`;
