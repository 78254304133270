import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ProductsCard from "../Products/ProductsList/ProductsCard";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";
import Pagination from "../../common/Pagination";

const SearchResults = () => {
  // Get the search results from the location state
  const location = useLocation();
  const { filteredProducts, query } = location.state || {
    filteredProducts: [],
    query: "",
  };

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Calculate total items and pages
  const totalItems = filteredProducts.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Get products for the current page
  const currentProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <SearchResultsContainer>
      <h3>Search: {query}</h3>
      {currentProducts.length ? (
        <>
          <ProductsGrid>
            {currentProducts.map((product: any) => (
              <ProductsCard
                key={product._id}
                product={product}
                dashboardType={`store` || `distributor` || `admin`}
              />
            ))}
          </ProductsGrid>

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          )}
        </>
      ) : (
        <p>No products found for {query}</p>
      )}
    </SearchResultsContainer>
  );
};

export default SearchResults;

// Styled components
const SearchResultsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 2rem;

  h3 {
    font-size: 1.2rem;
    color: ${colors.dark};
    font-weight: 500;
    width: 90%;
    margin: 1rem auto auto auto;
    text-transform: capitalize;
  }
`;

const ProductsGrid = styled.div`
  width: 90%;
  margin: auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: ${screens.minitab}) {
    grid-template-columns: 1fr;
  }
`;
