import React from "react";
import { styled } from "styled-components";
import { colors, typography } from "../../../utils";
import StatusesTag from "../../../utils/StatusesTag";
import { Link } from "react-router-dom";

interface IDistributor {
  firstName: string;
  lastName: string;
  id: string;
}

interface ISingleOrder {
  orderId: string;
  totalPrice: any;
  id: string;
  isPaid?: boolean;
  createdAt?: any;
  isDelivered?: boolean;
  isConfirmed?: boolean;
}

interface IOrder {
  distributor: IDistributor;
  orders: ISingleOrder[];
}

const AdminOrderCard = ({ distributor, orders }: IOrder) => {
  const formatOrderDate = (createdAt: any) => {
    const orderDate = new Date(createdAt);
    const options = {
      year: "numeric" as const,
      month: "short" as const,
      day: "numeric" as const,
    };
    return orderDate.toLocaleDateString(undefined, options);
  };

  return (
    <Container>
      {orders.map((order) => (
        <Content key={order.id} to={`/admin-dashboard/orders/${order.orderId}`}>
          <div className="order">
            <Item>
              {distributor.firstName} {distributor.lastName}
            </Item>
          </div>
          <div className="order">
            <Item>{order.orderId}</Item>
          </div>
          <div className="date">
            <p>{formatOrderDate(order.createdAt)}</p>
          </div>
          <div className="price">
            <p>
              {order.totalPrice.toLocaleString("en-NG", {
                style: "currency",
                currency: "NGN",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="status">
            {!order.isPaid ? (
              <StatusesTag tagColor={colors.C4B} text="Not paid" />
            ) : order.isPaid && !order.isDelivered ? (
              <StatusesTag tagColor={colors.C2C} text="Payment Received" />
            ) : order.isPaid && !order.isDelivered ? (
              <StatusesTag tagColor={colors.C2B} text="Delivery in Progress" />
            ) : order.isPaid && order.isDelivered ? (
              <StatusesTag tagColor={colors.C2B} text="Order delivered" />
            ) : order.isConfirmed && order.isPaid && order.isDelivered ? (
              <StatusesTag tagColor={colors.C3A} text="Seller has confirmed" />
            ) : (
              ""
            )}
          </div>
        </Content>
      ))}
    </Container>
  );
};

export default AdminOrderCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.C2E};
`;

const Content = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid ${colors.C1D};
  padding: 0.5rem;
  gap: 1rem;
  text-decoration: none;

  div {
    flex: 1;
    text-align: left;
  }

  p {
    font: ${typography.Caption};
    text-transform: capitalize;
    color: ${colors.C1B};
    margin: 0;
  }
`;

const Item = styled.div`
  font: ${typography.Caption};
  color: ${colors.C1B};
  text-transform: capitalize;
`;
