import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface ProductItem {
  name: string;
  images: string[];
  brand: string;
  category: string;
  condition: string;
  description: string;
  price: number;
  countInStock: number;
  _id: string | undefined;
  discountPrice?: number;
}

interface CartItem {
  product: ProductItem;
  quantity: number;
  distributorId?: string;
}

interface CartState {
  cartItems: CartItem[];
  totalItems: number;
}

const initialState: CartState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems") || "[]").map(
    (item: any) => ({
      ...item,
      distributorId: item.distributorId || "",
    })
  ),
  totalItems: 0,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (
      state,
      action: PayloadAction<{
        product: ProductItem;
        quantity: number;
        distributorId?: string;
      }>
    ) => {
      const { product, quantity, distributorId } = action.payload;

      // Match existing items based on product ID and distributor ID (if provided)
      const existingItem = state.cartItems.find((item) => {
        const isSameProduct = item.product._id === product._id;
        const isSameDistributor =
          !distributorId || item.distributorId === distributorId;
        return isSameProduct && isSameDistributor;
      });

      if (existingItem) {
        existingItem.quantity += quantity;
      } else {
        state.cartItems.push({
          product,
          quantity,
          distributorId: distributorId || "",
        });
      }

      state.totalItems += quantity;
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    removeFromCart: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      state.cartItems = state.cartItems.filter(
        (item) => item.product._id !== productId
      );

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    clearCart: (state) => {
      state.cartItems = [];
      state.totalItems = 0;
      localStorage.removeItem("cartItems");
    },

    incrementQuantity: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      const cartItem = state.cartItems.find(
        (item) => item.product._id === productId
      );

      if (cartItem) {
        cartItem.quantity += 1;
        state.totalItems += 1;
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },

    decrementQuantity: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      const cartItem = state.cartItems.find(
        (item) => item.product._id === productId
      );

      if (cartItem && cartItem.quantity > 1) {
        cartItem.quantity -= 1;
        state.totalItems -= 1;
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },
  },
});

export const selectCart = (state: RootState) => state.cart;

export const {
  addToCart,
  removeFromCart,
  clearCart,
  incrementQuantity,
  decrementQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
