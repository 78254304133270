import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectDistributor } from "../../../app/features/distributorSlice";
import { useGetAllOrdersByUserQuery } from "../../../app/services/orderApi";
import { styled } from "styled-components";
import { colors, typography } from "../../../utils";
import { GiCheckMark } from "react-icons/gi";
import Spinner from "../../../utils/Spinner";
import StatusesTag from "../../../utils/StatusesTag";
import { MdClose } from "react-icons/md";

const DistributorOrders = () => {
  const { distributor } = useAppSelector(selectDistributor);
  const navigate = useNavigate();
  const distributorId = distributor.id;
  const [ordersData, setOrdersData] = useState([]);

  // Fetch all orders for the distributor
  const {
    data: orders,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllOrdersByUserQuery(distributorId, {
    skip: !distributorId,
  });

  if (!distributorId) {
    navigate("/sign-in/distributor");
    return null;
  }

  // Time format function for negotiations
  const formatOrderDate = (createdAt: any) => {
    const orderDate = new Date(createdAt);
    const now = new Date();

    const options = {
      year: "numeric" as const,
      month: "short" as const,
      day: "numeric" as const,
    };
    return orderDate.toLocaleDateString(undefined, options);
  };

  // useEffect(() => {
  //   if (orders) {
  //     setOrdersData(orders);
  //   }
  // }, [orders]);

  // useEffect(() => {
  //   if (isError) {
  //     const customError = error as CustomError;
  //     const errorMessage =
  //       customError?.data?.error || "Oops! Something went wrong";
  //     toast.error(errorMessage);
  //     return;
  //   }
  // }, [isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Orders</h3>
        {isLoading ? (
          <Spinner color={colors.C2C} />
        ) : isError ? (
          <p>Oops!!! Something went wrong...</p>
        ) : (
          <>
            <Heading>
              <h4 className="order">Order</h4>
              <h4 className="date">Date</h4>
              <h4 className="status">Status</h4>
              <h4 className="price">Price</h4>
              <h4 className="paid">Paid</h4>
              <h4 className="delivered">Delivered</h4>
            </Heading>

            <Body>
              {orders ? (
                orders.map((order: any) => {
                  return (
                    <Content key={order.id}>
                      <div className="order">
                        <Item
                          to={`/distributor-dashboard/order/details/${order.orderId}`}
                          // to={
                          //   order.isPaid
                          //     ? `/distributor-dashboard/order/details/${order.orderId}`
                          //     : `/distributor-dashboard/payment/${
                          //         order.orderId && order.orderId
                          //       }`
                          // }
                        >
                          {order.orderId}
                        </Item>
                      </div>
                      <div className="date">
                        <p>{formatOrderDate(order.createdAt)}</p>
                      </div>
                      <div className="status">
                        {!order.isPaid ? (
                          <StatusesTag tagColor={colors.C4B} text="Not paid" />
                        ) : order.isPaid && !order.isDelivered ? (
                          <StatusesTag
                            tagColor={colors.C2C}
                            text="Shipping in progress"
                          />
                        ) : order.isPaid && order.isDelivered ? (
                          <StatusesTag
                            tagColor={colors.C2B}
                            text="Order delivered"
                          />
                        ) : order.isConfirmed &&
                          order.isPaid &&
                          order.isDelivered ? (
                          <StatusesTag
                            tagColor={colors.C3A}
                            text="Seller has confirmed"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="price">
                        <p>
                          {order.totalPrice.toLocaleString("en-NG", {
                            style: "currency",
                            currency: "NGN",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </div>
                      <div className="paid">
                        {order.isPaid ? (
                          <GiCheckMark
                            color={colors.C1A}
                            style={{
                              margin: 0,
                              display: "flex",
                              padding: 0,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                        ) : !order.isPaid ? (
                          <MdClose
                            color={colors.C4A}
                            style={{
                              margin: 0,
                              display: "flex",
                              padding: 0,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                        ) : null}
                      </div>
                      <div className="delivered">
                        {order.isDelivered ? (
                          <GiCheckMark
                            color={colors.C1A}
                            style={{
                              margin: 0,
                              display: "flex",
                              padding: 0,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                        ) : !order.isDelivered ? (
                          <MdClose
                            color={colors.C4A}
                            style={{
                              margin: 0,
                              display: "flex",
                              padding: 0,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                        ) : null}
                      </div>
                    </Content>
                  );
                })
              ) : (
                <h3>No orders</h3>
              )}
            </Body>
          </>
        )}
      </Wrapper>
    </Container>
  );
};

export default DistributorOrders;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  margin: 1.5rem auto;

  h3 {
    font: ${typography.Lead};
    margin-bottom: 1rem;
    font-weight: 600;
    color: ${colors.C1A};
  }

  .order {
    width: 25%;
  }

  .date {
    width: 10%;
  }

  .status {
    width: 25%;
  }

  .price {
    width: 15%;
  }

  .paid {
    width: 5%;
  }

  .delivered {
    width: 7%;
  }
`;

const Heading = styled.div`
  display: flex;
  width: 100%;
  border-top: 0.5px solid ${colors.C1D};
  border-bottom: 0.5px solid ${colors.C1D};
  margin-bottom: 1rem;
  gap: 1rem;

  h4 {
    font: ${typography.Caption};
    margin: 0.5rem 0;
    font-weight: 600;
    color: ${colors.C1B};
  }
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  border-top: 0.5px solid ${colors.C1D};
  border-bottom: 0.5px solid ${colors.C1D};
  gap: 1rem;

  div {
    margin: 0.5rem 0;
  }

  p {
    font: ${typography.Caption};
    text-transform: capitalize;
    max-width: inherit;
    color: ${colors.C1B};
    margin: 0;
  }
`;

const Item = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${colors.C1B};
  font: ${typography.Caption};
  text-transform: capitalize;
`;

const Table = styled.table`
  width: 100%;
  margin: 0 auto 3rem;
  padding: 1rem;

  thead {
    border: 2px solid ${colors.C1D};
    border-bottom: 0.5px solid ${colors.C1D};
    margin-bottom: 1rem;
    background-color: ${colors.C1D};
  }

  tr {
  }

  th {
    color: ${colors.C1A};
    font: ${typography.Button};
  }

  tbody {
  }

  td {
    padding: 0.6rem 1rem;
    font: ${typography.Caption};
    text-align: center;
    align-items: center;
    margin: 0.4rem 0;
    &:nth-child(2) {
      text-align: left;
    }

    button {
      margin: auto;
      font: ${typography.Caption};
      background-color: ${colors.C1B};
      color: ${colors.C1H};
      padding: 0.3rem 0.6rem;
      border: none;
      border-radius: 0.25rem;
      cursor: pointer;

      &:hover {
        background-color: ${colors.C1C};
        color: ${colors.C1A};
        transition: all 0.2s ease-in;
      }
    }
  }
`;
