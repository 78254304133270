import React from "react";
import { styled } from "styled-components";
import { screens } from "../../utils";
import AuthCard from "./AuthCard";

const data = [
  {
    title: "Sign in as Admin",
    linkTo: "admin",
  },
  {
    title: "Sign in as Distributor",
    linkTo: "distributor",
  },
];

const SignInDefault = () => {
  return (
    <Container>
      <Wrapper>
        {data.map((item, index) => {
          return (
            <AuthCard key={index} linkTo={item.linkTo} title={item.title} />
          );
        })}
      </Wrapper>
    </Container>
  );
};

export default SignInDefault;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: 2rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;
