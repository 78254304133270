import React, { useEffect, useState } from "react";
import { useCreateProductMutation } from "../../../app/services/adminApi";
import { uploadImages } from "../../../lib/firebase-client";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { Form, Input, Label } from "../../../common/FormElements";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { CustomError } from "../../../../types";
import Spinner from "../../../utils/Spinner";

const AddProduct = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState<number>(0);
  const [countInStock, setCountInStock] = useState<number>(0);
  const [addProduct, { isSuccess, isError, error, isLoading }] =
    useCreateProductMutation();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedImages = Array.from(event.target.files);
      // Add the new images to the state
      setImages((prevImages) => [...prevImages, ...selectedImages]);

      // Create preview URLs for the images
      const selectedPreviews = selectedImages.map((file) =>
        URL.createObjectURL(file)
      );
      setImagePreviews((prevPreviews) => [
        ...prevPreviews,
        ...selectedPreviews,
      ]);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      // Upload images and get URLs (limit to 5 images)
      const imageUrls = await uploadImages(
        images.slice(0, 5),
        "products/images"
      );

      // Prepare product data
      const productData = {
        name,
        images: imageUrls,
        brand,
        category,
        description,
        price,
        countInStock,
      };

      // Call the mutation to add the product
      await addProduct(productData);

      // Reset form fields
      setName("");
      setImages([]);
      setImagePreviews([]);
      setBrand("");
      setCategory("");
      setDescription("");
      setPrice(0);
      setCountInStock(0);
    } catch (error) {}
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/admin-dashboard/products");
      toast.success("Product created successfully!");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Add New Product</h3>
        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label text="Name:" />
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>

            {/* Image Previews */}
            <FormGroup>
              <Label text="Images (Up to 5)" />
              <ImagePreviewWrapper>
                {imagePreviews.map((preview, index) => (
                  <ImagePreview key={index}>
                    <img src={preview} alt={`preview-${index}`} />
                  </ImagePreview>
                ))}
              </ImagePreviewWrapper>
              {/* File input for images */}
              {images.length < 5 && (
                <FileInput
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple
                  required={images.length === 0}
                />
              )}
            </FormGroup>

            <FormGroup>
              <Label text="Brand:" />
              <Input
                type="text"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label text="Category:" />
              <Input
                type="text"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label text="Description:" />
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label text="Price:" />
              <Input
                type="number"
                value={price}
                onChange={(e) => setPrice(Number(e.target.value))}
              />
            </FormGroup>

            <FormGroup>
              <Label text="Count In Stock:" />
              <Input
                type="number"
                value={countInStock}
                onChange={(e) => setCountInStock(Number(e.target.value))}
              />
            </FormGroup>

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? <Spinner color={colors.white} /> : "Add Product"}
            </SubmitButton>
          </Form>
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};

export default AddProduct;

// Styled components for layout and styling

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: ${colors.lightBack};
  padding: 2rem 0;
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${colors.black};
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  gap: 0.7rem;
`;

const FileInput = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  background-color: ${colors.white};
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${colors.primary};
  }
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  outline: none;
  resize: vertical;
  transition: border-color 0.3s ease-in-out;
  min-height: 120px;

  &:focus {
    border-color: ${colors.primary};
  }
`;

const ImagePreviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const ImagePreview = styled.div`
  width: 3rem;
  height: 3rem;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grey};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SubmitButton = styled.button`
  padding: 1rem;
  font-size: 1.25rem;
  background-color: ${colors.yellow};
  color: ${colors.black};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${colors.grey};
  }

  @media (max-width: ${screens.tab}) {
    font-size: 1.1rem;
  }
`;
