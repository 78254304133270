import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const url = process.env.REACT_APP_BASE_URI as string;

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}orders/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.distributor.token || state.admin.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Orders"],

  endpoints: (builder) => ({
    //Create a new order
    createOrder: builder.mutation({
      query: (newOrder) => ({
        url: "/",
        method: "POST",
        body: newOrder,
      }),
    }),

    getOrderById: builder.query({
      query: ({ orderId, distributorId }) => `/${orderId}/${distributorId}`,
    }),

    getAllOrdersByUser: builder.query({
      query: (distributorId) => `/${distributorId}`,
    }),

    payForOrder: builder.mutation({
      query: (orderId) => ({
        url: `/${orderId}/pay`,
        method: "PATCH",
      }),
      invalidatesTags: ["Orders"],
    }),

    generateConfirmationCode: builder.mutation({
      query: (orderId) => ({
        url: `/${orderId}/generate-confirmation-code`,
        method: "POST",
      }),
    }),

    confirmDelivery: builder.mutation<
      void,
      { orderId: string; confirmationCode: string }
    >({
      query: ({ orderId, confirmationCode }) => ({
        url: `/${orderId}/confirm-delivery`,
        method: "POST",
        body: { confirmationCode },
      }),
    }),

    confirmReceipt: builder.mutation<
      void,
      { orderId: string; confirmationCode: string }
    >({
      query: ({ orderId, confirmationCode }) => ({
        url: `/${orderId}/confirm-receipt`,
        method: "POST",
        body: { confirmationCode },
      }),
    }),

    // Generate Invoice
    generateInvoice: builder.mutation({
      query: ({ distributorId, orderId }) => ({
        url: `${distributorId}/${orderId}/generate-invoice`,
        method: "PATCH",
      }),
    }),

    // Get Invoice by Id
    getInvoiceById: builder.query({
      query: ({ orderId, distributorId }) =>
        `/${distributorId}/${orderId}/invoice-details`,
    }),

    // Add Proof of Payment
    addProofOfPayment: builder.mutation({
      query: ({ orderId, proofOfPayment }) => ({
        url: `/${orderId}/add-proof-of-payment`,
        method: "PATCH",
        body: { proofOfPayment },
      }),
      invalidatesTags: ["Orders"],
    }),

    //...
  }),
});

export const {
  useCreateOrderMutation,
  useGetOrderByIdQuery,
  useGetAllOrdersByUserQuery,
  usePayForOrderMutation,
  useConfirmDeliveryMutation,
  useConfirmReceiptMutation,
  useGenerateConfirmationCodeMutation,
  useGenerateInvoiceMutation,
  useGetInvoiceByIdQuery,
  useAddProofOfPaymentMutation,
} = orderApi;

export default orderApi;
