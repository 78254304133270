import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface AdminState {
  firstName: string;
  lastName: string;
  email: string;
  tag: string;
  id: string | null;
}

interface InitialState {
  admin: AdminState;
  token: string | null;
}

const initialState: InitialState = {
  admin: {
    firstName: "",
    lastName: "",
    email: "",
    tag: "",
    id: null,
  },
  token: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    createAdmin: (state, action) => {
      // Handle creating a new Admin if needed
    },

    setAdmin: (state, action) => {
      const { firstName, lastName, email, tag, id, token } = action.payload;

      localStorage.setItem(
        "admin",
        JSON.stringify({
          id,
          firstName,
          lastName,
          tag,
          email,
          token,
        })
      );

      state.admin.id = id;
      state.admin.firstName = firstName;
      state.admin.lastName = lastName;
      state.admin.tag = tag;
      state.admin.email = email;
      state.token = token;
    },

    logoutAdmin: (state) => {
      localStorage.clear();
      state.admin.firstName = "";
      state.admin.lastName = "";
      state.admin.email = "";
      state.admin.tag = "";
      state.admin.id = null;
      state.token = null;
    },
  },
});

export const selectAdmin = (state: RootState) => state.admin;

export const { createAdmin, setAdmin, logoutAdmin } = adminSlice.actions;

export default adminSlice.reducer;
