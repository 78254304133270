import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import Pagination from "../../../common/Pagination";
import { colors } from "../../../utils";
import { useGetADistributorByIdQuery } from "../../../app/services/distributorApi";

const SingleDistributorStaff = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetADistributorByIdQuery(id);

  if (isLoading) {
    return <Container>Loading...</Container>;
  }

  if (isError) {
    return <Container>Error loading distributor details.</Container>;
  }

  return (
    <Container>
      <Wrapper>
        <DistributorDetails>
          <TitleWrapper>
            <h2>Distributor Details</h2>
          </TitleWrapper>

          <p>
            <strong>Full Name:</strong> {data?.firstName}{" "}
            {data?.lastName}
          </p>
          <p>
            <strong>Email:</strong> {data?.email}
          </p>
          <p>
            <strong>Phone Number:</strong> {data?.phoneNo}
          </p>
          <p>
            <strong>State:</strong> {data?.state}
          </p>
          <p>
            <strong>LGA:</strong> {data?.lga}
          </p>
          <p>
            <strong>Member Since:</strong>{" "}
            {new Date(data?.createdAt).toLocaleString()}
          </p>
        </DistributorDetails>
      </Wrapper>
    </Container>
  );
};

export default SingleDistributorStaff;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 2rem;
  margin: 2rem auto;
`;

const DistributorDetails = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${colors.lightBack};

  h2 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: ${colors.dark};
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    color: ${colors.dark};

    strong {
      font-weight: 600;
      color: ${colors.dark};
    }
  }
`;

const OrdersDetails = styled.div`
  padding: 1rem;

  h2 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: ${colors.dark};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;
