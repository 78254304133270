import React, { useState } from "react";
import { useGetAllRaffleCodesQuery } from "../../../app/services/adminApi";
import { styled } from "styled-components";
import { colors, typography } from "../../../utils";
import * as XLSX from "xlsx";
import Pagination from "../../../common/Pagination";

const ITEMS_PER_PAGE = 3;

const AdminRaffle: React.FC = () => {
  const { data, isLoading, isError } = useGetAllRaffleCodesQuery({});
  const [activeTab, setActiveTab] = useState<"distributors" | "artisans">(
    "distributors"
  );
  const [dateFilter, setDateFilter] = useState<
    "all" | "today" | "week" | "month" | "custom"
  >("all");
  const [customDate, setCustomDate] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);

  const distributorCodes = data?.distributorCodes || [];
  const artisanCodes = data?.artisanCodes || [];

  const filterByDate = (orders: any[]) => {
    if (dateFilter === "all") {
      return orders; // No filtering, return all orders.
    }

    const now = new Date();
    const startOfWeek = new Date();
    startOfWeek.setDate(now.getDate() - now.getDay());
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    let startDate: Date | null = null;

    if (dateFilter === "today") {
      startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
    } else if (dateFilter === "week") {
      startDate = startOfWeek;
    } else if (dateFilter === "month") {
      startDate = startOfMonth;
    } else if (dateFilter === "custom" && customDate) {
      startDate = new Date(customDate);
    }

    return orders.filter((order: any) => {
      const orderDate = new Date(order.createdAt); // Use createdAt for filtering.
      return !startDate || orderDate >= startDate;
    });
  };

  const exportToExcel = (distributors: any[], artisans: any[]) => {
    // Prepare the data for the Distributors sheet
    const distributorRows = [
      [
        "Name",
        "Email",
        "Phone",
        "Raffle Code",
        "Quantity",
        "Amount",
        "Distributor Name",
      ],
      ...distributors.flatMap((item: any) =>
        item.orders
          .filter((order: any) => order?.raffleCode)
          .map((order: any) => [
            item.fullName,
            item.email,
            item.phoneNo,
            order.raffleCode,
            order.quantity || "N/A",
            order.amount?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "N/A",
            item.distributor
              ? `${item.distributor.firstName} ${item.distributor.lastName}`
              : "N/A",
          ])
      ),
    ];

    // Prepare the data for the Artisans sheet
    const artisanRows = [
      [
        "Name",
        "Email",
        "Phone",
        "Raffle Code",
        "Quantity",
        "Amount",
        "Distributor Name",
      ],
      ...artisans.flatMap((item: any) =>
        item.orders
          .filter((order: any) => order?.raffleCode)
          .map((order: any) => [
            item.fullName,
            item.email,
            item.phoneNo,
            order.raffleCode,
            order.quantity || "N/A",
            order.amount?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) || "N/A",
            item.distributor
              ? `${item.distributor.firstName} ${item.distributor.lastName}`
              : "N/A",
          ])
      ),
    ];

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add data to the sheets
    const distributorSheet = XLSX.utils.aoa_to_sheet(distributorRows);
    const artisanSheet = XLSX.utils.aoa_to_sheet(artisanRows);

    // Append the sheets to the workbook
    XLSX.utils.book_append_sheet(wb, distributorSheet, "Distributors");
    XLSX.utils.book_append_sheet(wb, artisanSheet, "Artisans");

    // Write the workbook to a Blob and trigger the download
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "raffle_data.xlsx";
    link.click();
  };

  // Function to convert the string to ArrayBuffer (necessary for binary data)
  const s2ab = (s: string) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  // Get the active codes based on the active tab
  const activeCodes =
    activeTab === "distributors" ? distributorCodes : artisanCodes;

  // Filter the codes by date and paginate
  const filteredCodes = activeCodes.filter((item: any) =>
    filterByDate(item.orders).some((order: any) => order.raffleCode)
  );

  const paginatedCodes = filteredCodes.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Handle Page Change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <Wrapper>
        <Filters>
          <DownloadButton
            onClick={() => exportToExcel(distributorCodes, artisanCodes)}
          >
            Download Excel
          </DownloadButton>
          <DateFilter>
            <label>
              Date Range:
              <select
                value={dateFilter}
                onChange={(e) =>
                  setDateFilter(
                    e.target.value as
                      | "all"
                      | "today"
                      | "week"
                      | "month"
                      | "custom"
                  )
                }
              >
                <option value="all">All Dates</option>
                <option value="today">Today</option>
                <option value="week">This Week</option>
                <option value="month">This Month</option>
                <option value="custom">Custom Date</option>
              </select>
            </label>
            {dateFilter === "custom" && (
              <input
                type="date"
                value={customDate}
                onChange={(e) => setCustomDate(e.target.value)}
              />
            )}
          </DateFilter>
        </Filters>
        <Tabs>
          <Tab
            active={activeTab === "distributors"}
            onClick={() => {
              setActiveTab("distributors");
              setCurrentPage(1); // Reset to page 1 on tab change
            }}
          >
            Distributors
          </Tab>
          <Tab
            active={activeTab === "artisans"}
            onClick={() => {
              setActiveTab("artisans");
              setCurrentPage(1); // Reset to page 1 on tab change
            }}
          >
            Artisans
          </Tab>
        </Tabs>

        <CodesList>
          {paginatedCodes.map((item: any) => (
            <CodeCard key={item.id}>
              <p>
                <strong>Name:</strong> {item.fullName}
              </p>
              <p>
                <strong>Email:</strong> {item.email}
              </p>
              <p>
                <strong>Phone:</strong> {item.phoneNo}
              </p>
              <OrderList>
                {filterByDate(item.orders)
                  .filter((order: any) => order.raffleCode)
                  .map((order: any, index: number) => (
                    <OrderCard key={index}>
                      <p>
                        <strong>Raffle Code:</strong> {order.raffleCode}
                      </p>
                      <p>
                        <strong>Quantity:</strong> {order.quantity || "N/A"}
                      </p>
                      <p>
                        <strong>Amount:</strong>{" "}
                        {order.amount?.toLocaleString("en-NG", {
                          style: "currency",
                          currency: "NGN",
                        }) || "N/A"}
                      </p>
                    </OrderCard>
                  ))}
              </OrderList>
            </CodeCard>
          ))}
        </CodesList>

        {isLoading && <NoData>Loading...</NoData>}
        {isError && <NoData>Error fetching data.</NoData>}
        {!isLoading && !isError && filteredCodes.length === 0 && (
          <NoData>No data available.</NoData>
        )}
        <Pagination
          currentPage={currentPage}
          totalItems={filteredCodes.length}
          itemsPerPage={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
        />
      </Wrapper>
    </Container>
  );
};

export default AdminRaffle;

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const DownloadButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${colors.C2B};
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font: ${typography.Body};

  &:hover {
    background-color: ${colors.C2C};
  }
`;

const DateFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: 0.9rem;
  }

  select,
  input[type="date"] {
    font-size: 0.9rem;
    margin-left: 0.5rem;
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;
`;

const Tab = styled.button<{ active: boolean }>`
  padding: 0.5rem 1rem;
  border: none;
  background-color: ${({ active }) => (active ? colors.C2C : "transparent")};
  color: ${({ active }) => (active ? colors.C1A : "#000")};
  font: ${typography.Body};
  cursor: pointer;
  border-bottom: ${({ active }) =>
    active ? `2px solid ${colors.C1B}` : "none"};

  &:hover {
    color: ${colors.C1B};
  }
`;

const CodesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 1rem;
`;

const CodeCard = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
`;

const OrderList = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const OrderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid ${colors.C1D};
  background-color: ${colors.C2E};
  border-radius: 4px;

  p {
    margin: 0;
    font: ${typography.Caption};
    color: ${colors.C1B};

    strong {
      font: ${typography.Caption};
      color: ${colors.C1B};
      font-weight: 600;
    }
  }
`;

const NoData = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: ${colors.C2B};
`;
