// src/firebaseConfig.ts

import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { getStorage, ref, deleteObject, uploadBytes, getDownloadURL } from "firebase/storage";

// Define the shape of your Firebase config object
interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const firebaseConfig: FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
  appId: process.env.REACT_APP_FIREBASE_APP_ID!,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const storage = firebase.storage();
export const firestore = firebase.firestore();

// Function to upload images
export const uploadImage = (
  file: File,
  folderPath: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Validate if the file is an image
    if (!file.type.startsWith("image/")) {
      reject(new Error("Only image files are allowed"));
      return;
    }

    const storageRef = storage.ref(`${folderPath}/${file.name}`);
    const uploadTask = storageRef.put(file, { contentType: file.type });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Track the upload progress if needed
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};

// Function to upload multiple images
export const uploadImages = (
  files: File[],
  folderPath: string
): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    const uploadPromises: Promise<string>[] = [];

    // Validate if all files are images
    for (const file of files) {
      if (!file.type.startsWith("image/")) {
        reject(new Error("Only image files are allowed"));
        return;
      }

      const storageRef = storage.ref(`${folderPath}/${file.name}`);
      const uploadTask = storageRef.put(file, { contentType: file.type });

      // Create a promise for each upload
      const uploadPromise = new Promise<string>(
        (uploadResolve, uploadReject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Track the upload progress if needed
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${progress}% done`);
            },
            (error) => {
              // Handle unsuccessful uploads
              uploadReject(error);
            },
            () => {
              // Handle successful uploads on complete
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then((downloadURL) => {
                  uploadResolve(downloadURL);
                })
                .catch(uploadReject);
            }
          );
        }
      );

      uploadPromises.push(uploadPromise);
    }

    // Wait for all uploads to complete
    Promise.all(uploadPromises)
      .then((urls) => {
        resolve(urls);
      })
      .catch(reject);
  });
};


// Delete an image from Firebase
export const deleteImageFromFirebase = async (imageUrl: any) => {
  try {
    const storageRef = ref(storage, imageUrl);
    await deleteObject(storageRef);
    console.log("Image deleted successfully from Firebase");
  } catch (error) {
    console.error("Error deleting image from Firebase:", error);
    throw error;
  }
};

// Upload a new image to Firebase and get its URL
export const uploadImageToFirebase = async (file: any) => {
  try {
    const storageRef = ref(storage, `products/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl;
  } catch (error) {
    console.error("Error uploading image to Firebase:", error);
    throw error;
  }
};
