import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../utils";
import { useGetAllPaymentsQuery } from "../../../app/services/adminApi";
import Pagination from "../../../common/Pagination";

const AllPayments = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const { data, isLoading, isSuccess, isError } = useGetAllPaymentsQuery({});

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Paginate data
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedPayments = data?.payments.slice(startIndex, endIndex) || [];
  const totalItems = data?.payments.length || 0;

  return (
    <Wrapper>
      <TitleWrapper>
        <h2>All Payments Received</h2>
        {isSuccess && (
          <Summary>
            <h3>Total Sum: ₦{data.totalSum.toLocaleString()}</h3>
          </Summary>
        )}
      </TitleWrapper>
      {isLoading && <p>Loading payments...</p>}
      {isError && <p>Failed to load payments.</p>}
      {isSuccess && (
        <>
          <Table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Distributor</th>
                <th>Email</th>
                <th>Phone No</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {paginatedPayments.map((payment: any) => (
                <tr key={payment._id}>
                  <td>{payment.orderId}</td>
                  <td>
                    {payment.distributor.firstName}{" "}
                    {payment.distributor.lastName}
                  </td>
                  <td>{payment.distributor.email}</td>
                  <td>{payment.distributor.phoneNo}</td>
                  <td>₦{payment.totalPrice.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Wrapper>
  );
};

export default AllPayments;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  margin: 1rem auto;

  h2 {
    color: ${colors.black};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.4rem;
    text-align: left;
    border: 1px solid ${colors.C2D};
  }

  th {
    background-color: ${colors.C2E};
    color: ${colors.black};
    font-size: 1rem;
    font-weight: 500;
  }

  td {
    color: ${colors.black};
    font-size: 0.8rem;
  }
`;

const Summary = styled.div`
  display: flex;
  justify-content: flex-end;

  h3 {
    color: ${colors.black};
  }
`;
