export const colors = {
  dark: "#444746",
  black: "#1f1f1f",
  bluePry: "#2667D5",
  blueSec: "#C2E7FF",
  lightTer: "#EAEFF8",
  blueLight: "#F8FBFF",
  blueBack: "#EBF2FE",
  white: "#FFFFFF",
  offWhite: "#FDFCFD",
  grey: "#C7C7C7",
  greyText: "#0f0f0f",
  lightBack: "#F8F8F8",

  red: "#EA4335",
  blue: "#4285F4",
  yellow: "#FBBC05",
  green: "#34A853",
  
  orange: "#EC6236",
  secondary: "#0028E9",
  //primary: "#082552",
  primary: "#000080",
  lightOrange: "#FBF7F1",
  C1A: "#151515",
    C1B: "#575757",
    C1C: "#A9A9A9",
    C1D: "#D1D1D1",
    C1E: "#EBEBEB",
    C1F: "#F5F5F5",
    C1H: "#F9F9F9",
    C1I: "#FFFFFF",

    C2A: "#6A983C",
    C2B: "#46760A",
    C2C: "#92C064",
    C2D: "#C8DEB3",
    C2E: "#F4F8EC",

    C3A: "#D0A866",
    C3B: "#B28A48",
    C3C: "#ECD2A6",
    C3D: "#FAEDD8",
    C3E: "#FFF9F0",

    C4A: "#E6704B",
    C4B: "#C7522D",
    C4C: "#EB8D70",
    C4D: "#F7C6B7",
    C4E: "#FFF1ED",

    C5A: "#37D0D6",
    C5B: "#19B2B8",
    C5C: "#7CD0D3",
    C5D: "#B9E6E8",
    C5E: "#ECF6F7"

};
