import React, { ReactNode } from "react";
import styled from "styled-components";
import Navigation from "../../common/Navigation";
import { colors, screens } from "../../utils";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";


interface ILayout {
  children: ReactNode;
}

const HomeLayout = ({ children }: ILayout) => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate("/sign-in");
  };
  return (
    <Container>
      <Navigation
        logoSrc={logo}
        buttonText="Sign in"
        btnBgColor={colors.yellow}
        onClick={handleSignIn}
        showSearch={true}
        dashboardType="store"
      />

      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

export default HomeLayout;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  min-height: 90vh;
`;


