import React from "react";
import { useParams } from "react-router-dom";
import { useGetOneOrderQuery } from "../../../app/services/adminApi";
import { styled } from "styled-components";

const AdminOrderDetails: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { data, isLoading, isError } = useGetOneOrderQuery({ orderId });

  if (isLoading) {
    return <Container>Loading...</Container>;
  }

  if (isError) {
    return <Container>Error loading order details.</Container>;
  }

  if (!data) {
    return <Container>No data found for this order.</Container>;
  }

  const {
    shippingAddress,
    orderItems,
    itemsPrice,
    totalPrice,
    distributor,
    isPaid,
    isDelivered,
    createdAt,
    updatedAt,
  } = data;

  return (
    <Container>
      <Wrapper>
        <Section>
          <Title>Order Details</Title>
          <Detail>
            <strong>Order ID:</strong> {data.orderId}
          </Detail>
          <Detail>
            <strong>Created At:</strong> {new Date(createdAt).toLocaleString()}
          </Detail>
          <Detail>
            <strong>Last Updated:</strong>{" "}
            {new Date(updatedAt).toLocaleString()}
          </Detail>
          <Detail>
            <strong>Paid:</strong> {isPaid ? "Yes" : "No"}
          </Detail>
          <Detail>
            <strong>Delivered:</strong> {isDelivered ? "Yes" : "No"}
          </Detail>
        </Section>

        <Section>
          <Title>Shipping Address</Title>
          <Detail>
            <strong>Full Name:</strong> {shippingAddress.fullName}
          </Detail>
          <Detail>
            <strong>Address:</strong> {shippingAddress.address}
          </Detail>
          <Detail>
            <strong>City:</strong> {shippingAddress.city}
          </Detail>
          <Detail>
            <strong>Postal Code:</strong> {shippingAddress.postalCode}
          </Detail>
          <Detail>
            <strong>Country:</strong> {shippingAddress.country}
          </Detail>
        </Section>

        <Section>
          <Title>Distributor Details</Title>
          <Detail>
            <strong>Name:</strong> {distributor.firstName}{" "}
            {distributor.lastName}
          </Detail>
          <Detail>
            <strong>Email:</strong> {distributor.email}
          </Detail>
          <Detail>
            <strong>Phone:</strong> {distributor.phoneNo}
          </Detail>
          <Detail>
            <strong>State:</strong> {distributor.state}
          </Detail>
          <Detail>
            <strong>LGA:</strong> {distributor.lga}
          </Detail>
        </Section>

        <Section>
          <Title>Order Items</Title>
          {orderItems.map((item: any) => (
            <ItemCard key={item._id}>
              {item?.product?.images?.[0] ? (
                <img
                  src={item.product.images[0]}
                  alt={item?.product?.name || "Product"}
                />
              ) : (
                <PlaceholderImage>No Image</PlaceholderImage>
              )}
              <div>
                <Detail>
                  <strong>Product Name:</strong> {item?.product?.name || "N/A"}
                </Detail>
                <Detail>
                  <strong>Quantity:</strong> {item?.quantity || 0}
                </Detail>
                <Detail>
                  <strong>Price:</strong> ₦
                  {item?.product?.price?.toLocaleString() || "N/A"}
                </Detail>
                <Detail>
                  <strong>Total:</strong> ₦
                  {(item?.quantity && item?.product?.price
                    ? item.quantity * item.product.price
                    : 0
                  ).toLocaleString()}
                </Detail>
              </div>
            </ItemCard>
          ))}
        </Section>

        <Section>
          <Title>Pricing</Title>
          <Detail>
            <strong>Items Price:</strong> ₦{itemsPrice.toLocaleString()}
          </Detail>

          <Detail>
            <strong>Total Price:</strong> ₦{totalPrice.toLocaleString()}
          </Detail>
        </Section>
      </Wrapper>
    </Container>
  );
};

export default AdminOrderDetails;

const Container = styled.div`
  padding: 2rem;
  background-color: #f9f9f9;
`;

const Wrapper = styled.div`
  max-width: 900px;
  margin: auto;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
`;

const Detail = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #555;

  strong {
    font-weight: bold;
    color: #333;
  }
`;

const ItemCard = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
  }

  div {
    flex-grow: 1;
  }
`;

const PlaceholderImage = styled.div`
  width: 60px;
  height: 60px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  font-size: 0.8rem;
  border-radius: 4px;
`;
