import React, { useState } from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import ProductImageCard from "./ProductImageCard";
import SmallCountdownTimer from "../../AdminScreens/DiscountManager/SmallCountdownTimer";
import { colors } from "../../../utils";

interface PriceProps {
  hasDiscount: boolean;
}

interface ProductItemProps {
  product: {
    _id: string;
    name: string;
    price: number;
    countInStock: number;
    images: string[];
    discountTime?: string;
    discountPrice?: number;
    discountCode?: number;
  };
  dashboardType: "store" | "distributor" | "admin";
}

const ProductsCard: React.FC<ProductItemProps> = ({
  product,
  dashboardType,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageSelect = (image: string) => {
    setSelectedImage(image);
  };

  // Determine the path based on the dashboard type
  const generatePath = () => {
    switch (dashboardType) {
      case "store":
        return `/store/${product._id}`;
      case "distributor":
        return `/distributor-dashboard/marketplace/products/${product._id}`;
      case "admin":
        return `/admin-dashboard/products/${product._id}`;
      default:
        return `/store/${product._id}`;
    }
  };

  return (
    <Container>
      <Wrapper>
        <ProductImageCard
          images={product.images}
          selectedImage={selectedImage}
          onImageSelect={handleImageSelect}
          productName={product.name}
        />

        <ProductInfo to={generatePath()}>
          <TitleContainer>
            <h4>{product.name}</h4>
          </TitleContainer>

          <Content>
            <DiscountWrapper>
              <PriceContainer>
                <Price hasDiscount={!!product.discountPrice}>
                  {product.price.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Price>
                {product.discountPrice && (
                  <DiscountPrice>
                    {product.discountPrice.toLocaleString("en-NG", {
                      style: "currency",
                      currency: "NGN",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </DiscountPrice>
                )}
              </PriceContainer>
              {product.discountCode && (
                <DiscountCode>{product.discountCode}% Off</DiscountCode>
              )}
            </DiscountWrapper>
            {product.discountTime && (
              <SmallCountdownTimer
                targetDate={new Date(product.discountTime).toISOString()}
              />
            )}
          </Content>
        </ProductInfo>
      </Wrapper>
    </Container>
  );
};

export default ProductsCard;

const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.25rem;
  height: fit-content;
  border: 1.5px solid ${colors.C1D};

  &:hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 16px;
    transition: all 0.3s ease-in-out;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  gap: 1rem;
`;

const ProductInfo = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  text-decoration: none;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;

  h4 {
    font-weight: 500;
    color: ${colors.black};
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const DiscountWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`;

const Price = styled.p<PriceProps>`
  font-size: 1rem;
  color: ${(props) => (props.hasDiscount ? colors.grey : colors.black)};
  text-decoration: ${(props) => (props.hasDiscount ? "line-through" : "none")};
`;

const DiscountPrice = styled.p`
  font-size: 0.8rem;
  color: ${colors.black};
  font-weight: 500;
`;

const DiscountCode = styled.p`
  font-size: 0.8rem;
  color: ${colors.white};
  font-weight: 600;
  padding: 0.1rem 0.2rem;
  background-color: ${colors.green};
  border-radius: 0.25rem;
  width: fit-content;
`;
