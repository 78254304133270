import React, { useState } from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import AllDistributors from "./AllDistributors";
import { useAppSelector } from "../../../app/hooks";
import { selectAdmin } from "../../../app/features/adminSlice";
import Modal from "../../../common/Modal";
import { Button } from "../../../common/FormElements";
import AddDistributor from "./AddDistributor";

const AdminDistributors = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { admin } = useAppSelector(selectAdmin);

  const handleAddDistributor = () => {
    setIsOpen(true);
  };

  return (
    <Container>
      <Wrapper>
        <AllDistributors />
        {(admin.tag === "ceo" || admin.tag === "manager") && (
          <AddDistributorWrapper>
            <h3>Onboard a New Distributor</h3>
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
              <AddDistributor closeModal={() => setIsOpen(false)} />
            </Modal>
            <Button
              type="button"
              color={colors.black}
              backgroundColor={colors.yellow}
              border="none"
              onClick={handleAddDistributor}
              text="Onboard a Distributor"
            />
          </AddDistributorWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

export default AdminDistributors;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  gap: 2rem;
  flex-direction: column;
  margin: 2rem auto;
`;

const AddDistributorWrapper = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    padding: 0;
    border-left: none;
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.dark};
  }
`;
