import React from "react";
import { useGetDistributorPaymentsQuery } from "../../../app/services/distributorApi";
import { useAppSelector } from "../../../app/hooks";
import { selectDistributor } from "../../../app/features/distributorSlice";
import { colors } from "../../../utils";
import { styled } from "styled-components";

const DistributorTransactions = () => {
  const { distributor } = useAppSelector(selectDistributor);
  const id = distributor.id;
  const { data, isLoading, isError } = useGetDistributorPaymentsQuery(id);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  const { orders = [], payments = { paidOrders: [], totalPayments: 0 } } =
    data || {};

  return (
    <Wrapper>
      <h2>Distributor Transactions</h2>

      {/* Orders Table */}
      <Table>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Full Name</th>
            <th>Total Price</th>
            <th>Paid</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order: any) => (
            <tr key={order._id}>
              <td>{order.orderId}</td>
              <td>{order.shippingAddress.fullName}</td>
              <td>₦{order.totalPrice.toLocaleString()}</td>
              <td>{order.isPaid ? "Yes" : "No"}</td>
              <td>{new Date(order.createdAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Payments Summary */}
      <Summary>
        <h3>Total Payments: ₦{payments.totalPayments.toLocaleString()}</h3>
      </Summary>

      {/* Payments Table */}
      <Table>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Full Name</th>
            <th>Payment Date</th>
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {payments.paidOrders.length > 0 ? (
            payments.paidOrders.map((order: any) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td>
                  {orders.find((o: any) => o._id === order._id)?.shippingAddress
                    .fullName || "N/A"}
                </td>
                <td>
                  {orders.find((o: any) => o._id === order._id)?.paidAt
                    ? new Date(
                        orders.find((o: any) => o._id === order._id).paidAt
                      ).toLocaleString()
                    : "N/A"}
                </td>
                <td>₦{order.totalPrice.toLocaleString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>No payments made</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default DistributorTransactions;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 1.5rem;
  margin: 1rem auto;

  h2 {
    color: ${colors.black};
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.4rem;
    text-align: left;
    border: 1px solid ${colors.C2D};
  }

  th {
    background-color: ${colors.C2E};
    color: ${colors.black};
    font-size: 1rem;
    font-weight: 500;
  }

  td {
    color: ${colors.black};
    font-size: 0.8rem;
  }
`;

const Summary = styled.div`
  display: flex;
  justify-content: flex-end;

  h3 {
    color: ${colors.black};
  }
`;
