import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface IShipping {
  fullName: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
}

// Load shipping information from localStorage if it exists
const loadShippingFromLocalStorage = (): IShipping | null => {
  const shippingData = localStorage.getItem("shipping");
  return shippingData ? JSON.parse(shippingData) : null;
};

const initialState: IShipping = loadShippingFromLocalStorage() || {
  fullName: "",
  address: "",
  city: "",
  postalCode: "",
  country: "",
};

export const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    setShipping: (state, action: PayloadAction<IShipping>) => {
      // Update the state based on the payload
      return {
        ...state,
        ...action.payload,
      };
    },
    clearShipping: (state) => {
      // Clear shipping from localStorage
      localStorage.removeItem("shipping");

      // Reset state to initial values
      return {
        fullName: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",
      };
    },
  },
});

// Selectors
export const selectShipping = (state: RootState) => state.shipping;

// Actions
export const { setShipping, clearShipping } = shippingSlice.actions;

export default shippingSlice.reducer;
