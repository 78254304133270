import { styled } from "styled-components";
import { colors } from "../../../utils";
import { Link } from "react-router-dom";

interface IDashboardCard {
  backgroundColor: string;
  icon: any;
  title: string;
  count: any;
  linkTo: string;
}

const DashboardCard = ({
  backgroundColor,
  icon,
  title,
  count,
  linkTo,
}: IDashboardCard) => {
  let formattedCount = count;

  if (count >= 1000000) {
    formattedCount = (count / 1000000).toFixed(1) + "m";
  } else if (count >= 100000) {
    formattedCount = (count / 1000).toFixed(0) + "k";
  } else if (count >= 1000) {
    formattedCount = (count / 1000).toFixed(1) + "k";
  }

  return (
    <Container to={linkTo}>
      <Wrapper>
        <ContentWrapper>
          {count ? <h3>{formattedCount}</h3> : null}
          <IconWrapper style={{ backgroundColor: `${backgroundColor}` }}>
            {icon}
          </IconWrapper>
        </ContentWrapper>
        <TextWrapper>
          <p>{title}</p>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};

export default DashboardCard;

const Container = styled(Link)`
  display: flex;
  width: 100%;
  border-radius: 0.5rem;
  background-color: ${colors.C1E};
  text-decoration: none;
`;

const Wrapper = styled.div`
  align-items: center;
  margin: 1rem 1rem 1rem 1.5rem;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  width: 100%;
`;

const IconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 1.5rem;
    color: ${colors.black};
    margin: 0;
    padding: 0;
    display: flex;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;

  p {
    font-size: 0.8rem;
    color: ${colors.black};
  }
`;
