import React from "react";
import { styled } from "styled-components";

interface ProductImageCardProps {
  images: string[];
  selectedImage: string | null;
  onImageSelect: (image: string) => void;
  productName: string;
}

const ProductImageCard: React.FC<ProductImageCardProps> = ({
  images,
  selectedImage,
  onImageSelect,
  productName,
}) => {
  return (
    <ImageItem>
      <LargeImage>
        <img src={selectedImage || images[0]} alt={productName} />
      </LargeImage>
      {images.length > 0 && (
        <ThumbnailContainer>
          {images.map((image, index) => (
            <Thumbnail
              key={index}
              src={image}
              alt={`${productName} thumbnail - ${index}`}
              onClick={() => onImageSelect(image)}
            />
          ))}
        </ThumbnailContainer>
      )}
    </ImageItem>
  );
};

export default ProductImageCard;

const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 60%;
  gap: 0.5rem;
`;

const LargeImage = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ThumbnailContainer = styled.div`
  display: flex;
  height: 2.5rem;
  gap: 0.5rem;
  overflow-x: auto;
  align-items: center;
  background-color: transparent;
`;

const Thumbnail = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;
