import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logoutAdmin, selectAdmin } from "../../app/features/adminSlice";
import {
  logoutDistributor,
  selectDistributor,
} from "../../app/features/distributorSlice";
import Logo from "../Logo";
import Nav from "./Nav";
import SearchBox from "../../components/SearchBox";
import { colors, screens } from "../../utils";

interface NavigationProps {
  logoSrc: string;
  buttonText: string;
  btnBgColor?: string;
  onClick: () => void;
  avatar?: any;
  firstName?: string;
  userToken?: string;
  navItems?: { id: number; title: string; linkTo: string }[];
  showSearch: boolean;
  dashboardType: "store" | "distributor" | "admin";
}

const Navigation: React.FC<NavigationProps> = ({
  logoSrc,
  buttonText,
  btnBgColor,
  onClick,
  avatar,
  firstName,
  userToken,
  navItems,
  showSearch,
  dashboardType,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Redux selectors to check who is logged in
  const { token: adminToken } = useAppSelector(selectAdmin);
  const { token: distributorToken } = useAppSelector(selectDistributor);

  // Check authentication state
  const isAdminLoggedIn = Boolean(adminToken);
  const isDistributorLoggedIn = Boolean(distributorToken);

  // Determine if the user is on the dashboard
  const isAdminDashboard = location.pathname.startsWith("/admin-dashboard");
  const isDistributorDashboard = location.pathname.startsWith(
    "/distributor-dashboard"
  );

  // Dynamic button text
  const btnText =
    (isAdminLoggedIn && isAdminDashboard) ||
    (isDistributorLoggedIn && isDistributorDashboard)
      ? "Logout"
      : !adminToken && !distributorToken
      ? "Sign In"
      : "Dashboard";

  // Handle button click logic
  const handleButtonClick = () => {
    if (btnText === "Dashboard") {
      // Navigate to respective dashboard
      if (isAdminLoggedIn) {
        navigate("/admin-dashboard/home");
      } else if (isDistributorLoggedIn) {
        navigate("/distributor-dashboard/home");
      }
    } else if (btnText === "Logout") {
      // Logout and redirect to home page
      if (isAdminLoggedIn) {
        dispatch(logoutAdmin());
      } else if (isDistributorLoggedIn) {
        dispatch(logoutDistributor());
      }
      navigate("/");
    } else {
      // Default behavior for button
      navigate("sign-in")
      onClick();
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (navRef.current && !navRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <Navbar>
      <Wrapper>
        <LogoWrapper>
          <Logo logo={logoSrc} />
        </LogoWrapper>
        <SearchWrapper>
          <SearchBox dashboardType={dashboardType} />
        </SearchWrapper>

        <ContentWrapper ref={navRef} open={isOpen}>
          <Nav
            logo={logoSrc}
            btnBgColor={btnBgColor}
            avatar={avatar}
            firstName={firstName}
            userToken={userToken}
            navItems={navItems}
            showSearch={showSearch}
            dashboardType={dashboardType}
            buttonText={btnText}
            handleBtnClick={handleButtonClick}
          />
        </ContentWrapper>

        <Hamburger onClick={toggleMenu}>
          <Bar />
          <Bar />
          <Bar />
        </Hamburger>
      </Wrapper>
    </Navbar>
  );
};

export default Navigation;

// Styled Components for Navigation (same as in your code)

// Styled Components for the Navigation
const Navbar = styled.nav<{ bgColor?: string; navHeight?: string }>`
  display: flex;
  width: 100%;
  position: relative;
  z-index: 1000;
  gap: 1.5rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${screens.tab}) {
    flex-direction: row;
    align-items: normal;
    gap: 1rem;
  }
`;

const LogoWrapper = styled.div`
  display: none;
  margin: 1rem auto 1rem 1rem;

  @media (max-width: ${screens.tab}) {
    display: flex;
  }
`;

const ContentWrapper = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
    background-color: ${colors.white};
    position: fixed;
    top: 0;
    right: 0;
    height: fit-content;
    width: 100%;
    padding: 2rem;
    align-items: normal;
    //box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    transition: transform 0.3s ease-in-out;
    z-index: 1001;
  }
`;

const NavItem = styled.li<{ itemSize?: string }>`
  cursor: pointer;
  font-size: ${({ itemSize }) => itemSize || "1rem"};
  font-weight: 700;
  width: fit-content;
  color: ${colors.white};

  @media (max-width: ${screens.tab}) {
    color: ${colors.black};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  margin: 1rem 1rem 0 0;

  @media (max-width: ${screens.tab}) {
    display: block;
  }
`;

const Bar = styled.div`
  width: 1.6rem;
  height: 0.2rem;
  background-color: ${colors.black};
  margin: 0.25rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 2.5rem;
  color: ${colors.black};
  position: absolute;
  top: 0.3rem;
  right: 1.2rem;
  cursor: pointer;

  @media (min-width: ${screens.tab}) {
    display: none;
  }
`;

const SearchWrapper = styled.div`
  display: none;
  width: 90%;
  margin: auto;

  @media (max-width: ${screens.tab}) {
    display: flex;
  }
`;
