import React, { useState } from "react";
import { useGetAllOrdersQuery } from "../../../app/services/adminApi";
import { styled } from "styled-components";
import Spinner from "../../../utils/Spinner";
import { colors, typography } from "../../../utils";
import AdminOrderCard from "./AdminOrderCard";
import Pagination from "../../../common/Pagination";

const AdminOrders = () => {
  const { data, isLoading, isError } = useGetAllOrdersQuery({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of orders to display per page

  // Sort data by createdAt in descending order (most recent first)
  const sortedData = data
    ? [...data].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    : [];

  // Calculate total items and pages
  const totalItems = sortedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Paginate sorted data
  const paginatedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Transform data into the shape expected by AdminOrderCard
  const formatOrders = (orders: any[]) =>
    orders.map((order) => ({
      distributor: {
        firstName: order.distributor.firstName,
        lastName: order.distributor.lastName,
        id: order.distributor._id,
      },
      orders: [
        {
          orderId: order.orderId,
          totalPrice: order.totalPrice,
          id: order._id,
          isPaid: order.isPaid,
          createdAt: order.createdAt,
          isDelivered: order.isDelivered,
          isConfirmed: order.isConfirmed,
        },
      ],
    }));

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Oops! Something went wrong</p>
        ) : sortedData.length > 0 ? (
          <>
            <Heading>
              <h4>Distributor</h4>
              <h4>Order</h4>
              <h4>Date</h4>
              <h4>Price</h4>
              <h4>Status</h4>
            </Heading>
            {formatOrders(paginatedData).map((formattedOrder, index) => (
              <AdminOrderCard
                key={index}
                distributor={formattedOrder.distributor}
                orders={formattedOrder.orders}
              />
            ))}
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <p>No orders found</p>
        )}
      </Wrapper>
    </Container>
  );
};

export default AdminOrders;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  flex-direction: column;
  gap: 1rem;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${colors.C1D};
  padding: 0.5rem;
  gap: 1rem;

  h4 {
    font: ${typography.Caption};
    font-weight: 600;
    color: ${colors.C1B};
    margin: 0;
    flex: 1;
    text-align: left;
  }
`;
